/* eslint-disable @typescript-eslint/no-unused-vars */
// Collection Manager

import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Stack, TextField } from '@fluentui/react';
import type { MenuButtonProps } from "@fluentui/react-components";
import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands, Skeleton, SkeletonItem, Spinner, SplitButton, Text, Popover, PopoverTrigger, PopoverSurface, Link, } from '@fluentui/react-components';
import { Add24Filled, Archive16Regular, ArchiveRegular, ArchiveSettingsRegular, ArrowCircleLeft32Filled, ArrowSync20Regular, ChevronRight16Filled, SearchRegular, DismissRegular, FolderOpenRegular, ArrowRight16Regular, ArrowLeft16Regular, Warning12Regular } from '@fluentui/react-icons';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { lightTheme } from '../../BlueEdgeTheme';
import { CollectionName, CustomJwtPayload, getCollectionsApi, retrieveFileApi, SelectedFile } from '../../api';
import BEAILogo from "../../assets/BEAILogoFinal.png";
import CreateNewIndex from '../CreateNewIndex/CreateNewIndex';
import { DeleteCollection } from '../DeleteCollection/DeleteCollection';
import FileStatus from '../FileStatus/FileStatus';
import UploadArea from '../UploadArea/UploadArea';
import styles from './CollectionManagerPopup.module.css';
import RenameCollection from '../RenameCollection/RenameCollection';
import CollectionCategoryManager from '../CollectionCategoryManager/CollectionCategoryManager';

type CollectionManagerPopupProps = {
    setRefreshCollection: (value: boolean) => void;
    refreshCollection: boolean;

}

// Styles for the skeleton
const useStyles = makeStyles({
    skeletonRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("20px"),
        gridTemplateColumns: "80% 20%",
        height: '5vh', // matches the height of the personaListItem
    },
    text: {
        color: lightTheme.colorBrandForeground1
    },
});

export const CollectionManagerPopup = ({ setRefreshCollection, refreshCollection }: CollectionManagerPopupProps) => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
    const [isAuthorizedToCreateIndex, setIsAuthorizedToCreateDb] = React.useState(false);
    const [isAuthorizedToDeleteIndex, setIsAuthorizedToDeleteDb] = React.useState(false);
    const [isAuthorizedToDeleteAll, setIsAuthorizedToDeleteAll] = React.useState(false);
    const [isAuthorizedToUpload, setIsAuthorizedToUpload] = React.useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false); // check if user is authorized to view THIS page
    const [isAuthorizedToViewQuickSettingsMenu, setIsAuthorizedToViewQuickSettingsMenu] = useState<boolean>(false);
    const [previousCollections, setPreviousCollections] = useState<CollectionName[] | null>(null);
    const [collections, setCollections] = useState<CollectionName[] | null>(null); // hold list of all collections
    const [selectedPdf, setSelectedPdf] = useState<SelectedFile | null>(null); // track which pdf is selected 
    const [selectedCollection, setSelectedCollection] = useState<CollectionName | undefined>(undefined); // track which collection is selected
    const [dialogVisible, setDialogVisible] = useState(false); // show/hide the dialog
    const [showUploadArea, setShowUploadArea] = useState(true); // show/hide upload area


    const [refreshGrid, setRefreshGrid] = useState(false); // refresh the DataGrid after upload/delete
    const [numberOfFilesInSelectedCollection, setNumberOfFilesInSelectedCollection] = useState<number>(0); // hold the number of files in the selected collection
    const [numberOfFailedFilesInSelectedCollection, setNumberOfFailedFilesInSelectedCollection] = useState<number>(0); // hold the number of Failed files in the selected collection
    const [autoRefresh, setAutoRefresh] = useState(false); // track if auto refresh is enabled
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [openUpload, setOpenUpload] = useState(false);
    const [hasUpload, setHasUpload] = useState(false);
    const [managerOpen, setManagerOpen] = useState(false);
    // Fluent UI styles
    const fuistyles = useStyles();
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [groupedCollections, setGroupedCollections] = useState<{ [key: string]: CollectionName[] }>({});

    const [uploadProgress, setUploadProgress] = useState<Map<string, number>>(new Map());// state to store the progress of each file in a map used in upload area and file status to display the progress of each file
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [collectionTotalPages, setCollectionTotalPages] = useState<number>(1);
    // Show Search Dialog
    const showSearch = () => {
        setIsSearchVisible(true);
    }

    const closeSearch = () => {
        setIsSearchVisible(false);
    }

    // Functions to show/hide the dialog
    const showDialog = () => {
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
        fetchCollectionNames(); // refresh the collections list
    };

    //show and hide the document collection manager
    const handleOpenManager = () => {
        if (!managerOpen) {
            setRefreshCollection(false)
            setManagerOpen(true);
            setShowUploadArea(false);
            groupCollectionsByCategory(collections || []);
            setCurrentPage(1);
            if (groupedCollections) {
                const getFirstCollectionName = () => {
                    // Get the first category alphabetically
                    const firstCategory = Object.keys(groupedCollections)
                        .sort((a, b) => a.localeCompare(b))[0];

                    // Get the first collection alphabetically from that category
                    const firstCollection = groupedCollections[firstCategory]
                        .sort((a, b) => a.display_name.localeCompare(b.display_name))[0];

                    // Return its display_name
                    return firstCollection;
                };
                handleSelectCollection(getFirstCollectionName());
            }
        }
    }


    // handle close Manager
    const handleCloseManager = () => {
        setManagerOpen(false);
        setShowUploadArea(true);
        setSelectedCollection(undefined);
        setOpenUpload(false);
        setRefreshCollection(true)
        setCurrentPage(1);
    }

    // get fresh access token and check roles
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                if (permissions.includes('view:CollectionsManager')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                if (permissions.includes('add:Databases')) {
                    setIsAuthorizedToCreateDb(true);
                }
                if (permissions.includes('delete:Databases')) {
                    setIsAuthorizedToDeleteDb(true);
                }
                if (permissions.includes('delete:Files')) {
                    setIsAuthorizedToDeleteAll(true);
                }
                if (permissions.includes('add:Files')) {
                    setIsAuthorizedToUpload(true);
                }
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewQuickSettingsMenu(true);
                }
            } catch (e) {
                console.error(e);
                alert("There was an error accessing your user permissions, please try to log in again.")
                navigate("/login");
            }
        }

        if (isAuthenticated) {
            checkRole();
        }
    }, [isAuthenticated, navigate, getAccessTokenSilently]);

    // Define function to fetch collection names
    const fetchCollectionNames = async () => {
        try {
            const token = await getAccessTokenSilently();
            const collectionNames = await getCollectionsApi(token) as CollectionName[];
            setPreviousCollections(collections);
            setCollections(collectionNames);


        } catch (error) {
            console.error('An error occurred while fetching the indexes:', error);
        };
    };

    //Fetch Collection Names on refresh
    useEffect(() => {
        if (refreshCollection) {
            fetchCollectionNames();
            setSelectedCollection(undefined);
        }
    }, [refreshCollection]);

    // Fetch collection names on load
    useEffect(() => {
        fetchCollectionNames();
    }, []);

    // detect changes and auto-select new collection
    useEffect(() => {
        if (collections && previousCollections) {
            // Find new collections by comparing current with previous
            const newCollections = collections.filter(current =>
                !previousCollections.some(prev => prev.id === current.id)
            );

            // If there's a new collection, select it
            if (newCollections.length > 0) {
                // Sort by creation time if available, or just take the first one
                const latestCollection = newCollections[0];
                handleSelectCollection(latestCollection);

                // Update the group collections
                groupCollectionsByCategory(collections);
            }
        }
    }, [collections]);

    //sort by category
    useEffect(() => {
        if (collections) {
            const grouped = collections.reduce((acc, c) => {
                if (!acc[c.category]) {
                    acc[c.category] = [];
                }
                acc[c.category].push(c);
                return acc;
            }, {} as { [key: string]: CollectionName[] });
            setGroupedCollections(grouped);
        }
    }, [collections])

    // Loading
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    // Permissions check
    if (!isAuthorizedToViewDocumentCollectionsManager) {
        return <div>You do not have Permission to view this page. Please contact your admin.</div>;
    }
    //search filters
    //filter the file list with the search box
    const searchFilterOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSearchFilter(newValue || '');
    }

    const clearFilter = () => {
        setSearchFilter('');
        setRefreshGrid(true)
    }

    const openUploadChange = () => {
        if (openUpload) {
            setOpenUpload(false);

        } else {
            setOpenUpload(true);
        }
    }
    //group the collections
    const groupCollectionsByCategory = (cArray: CollectionName[]) => {

    };

    const handleSelectCollection = (collection: CollectionName) => {
        setCurrentPage(1);
        setSelectedCollection(collection);
        clearFilter();
    }

    //show / hide the upload area
    const hideUploads = showUploadArea ? styles.hideUploads : '';

    const handleShowHideUpload = () => {
        if (showUploadArea) {
            setShowUploadArea(false);
            setRefreshGrid(true);

        } else {
            setShowUploadArea(true);
        }
    }

    //pagination controls
    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, collectionTotalPages));
    };

    const handlePageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageNumber = parseInt(event.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= collectionTotalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    //set the amount of buttons visible before ellipse added
    const MAX_VISIBLE_PAGES = 3;

    const renderPageButtons = () => {
        const buttons = [];
        const start = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
        const end = Math.min(collectionTotalPages, start + MAX_VISIBLE_PAGES - 1);

        if (start > 1) {
            buttons.push(
                <Button key="first" size='small' onClick={() => handlePageChange(1)} appearance='subtle'>
                    1
                </Button>
            );
            if (start > 2) buttons.push(<span key="ellipsis1" className={styles.paginationEllipsis}>...</span>);
        }

        for (let i = start; i <= end; i++) {
            buttons.push(
                <Button
                    size='small'
                    key={i}
                    onClick={() => handlePageChange(i)}
                    appearance={i === currentPage ? 'primary' : 'subtle'}
                    className={styles.paginationButton}
                >
                    {i.toString()}
                </Button>
            );
        }

        if (end < collectionTotalPages) {
            if (end < collectionTotalPages - 1) buttons.push(<span key="ellipsis2" className={styles.paginationEllipsis}>...</span>);
            buttons.push(
                <Button key="last" size='small' onClick={() => handlePageChange(collectionTotalPages)} appearance='subtle'>
                    {collectionTotalPages}
                </Button>
            );
        }

        return buttons;
    };



    return (

        <Dialog
            modalType='modal'
            open={managerOpen}
        >
            <DialogTrigger disableButtonEnhancement>
                <Link onClick={handleOpenManager}><ArchiveSettingsRegular /> Collection Manager</Link>
            </DialogTrigger>
            <DialogSurface className={styles.cmBody} >
                <DialogBody >
                    <DialogTitle>Collections Manager
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={styles.closeBtn} appearance="primary" icon={<DismissRegular />} onClick={handleCloseManager}></Button>
                            </DialogTrigger>
                        </DialogActions>


                    </DialogTitle>

                    <DialogContent >
                        {/* Side menu */}
                        <div className={styles.cmContainer}>
                            <div className={styles.collectionsFoldersToolbar}>
                                {/* Collection Category Manager Button */}
                                {isAuthorizedToViewQuickSettingsMenu ?
                                    <CollectionCategoryManager />
                                    : ''}
                                <div>
                                    <h4>Your Document Collections:</h4>
                                    <p>Document Collections {collections?.length} / 15</p>
                                </div>

                                {/* Collection List */}
                                <div className={styles.collectionsContainer}>
                                    {collections && collections.length > 0 ? (
                                        Object.keys(groupedCollections)
                                            .sort((a, b) => a.localeCompare(b))
                                            .map((category, index) => (
                                                <div key={index}>
                                                    <h3 className={styles.collectionCategory}>{category}</h3>
                                                    <div>
                                                        {groupedCollections[category]
                                                            .sort((a, b) => a.display_name.localeCompare(b.display_name))
                                                            .map((collection, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={styles.collectionItem}
                                                                    onClick={() => handleSelectCollection(collection)}
                                                                    style={collection.id === selectedCollection?.id ? { backgroundColor: '#a5e0e3' } : {}}
                                                                >
                                                                    <Archive16Regular />
                                                                    <Text
                                                                        as='span'
                                                                        size={300}
                                                                        className={styles.collectionName}
                                                                    >
                                                                        {/* Show the display_name of the collection */}
                                                                        {collection.display_name}
                                                                    </Text>
                                                                    <ChevronRight16Filled className={styles.chevron} />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        // display skeleton while loading
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <Text
                                                as='p'
                                                size={200}
                                                className={fuistyles.text}
                                            >Loading Collections...</Text>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                        </div>
                                    )}
                                </div>
                            </div>



                            <div className={styles.fileExplorerContainer}>
                                {/* Collection Manager ToolBar */}
                                <div className={styles.cmToolBar}>
                                    {/* Create New Collection */}
                                    <div className={styles.newCollectionBtn}>
                                        <Popover withArrow open={dialogVisible}>
                                            <PopoverTrigger disableButtonEnhancement>
                                                <Button
                                                    appearance="subtle"
                                                    icon={<Add24Filled />
                                                    }
                                                    disabled={collections?.length == 15 ? true : false}
                                                    onClick={showDialog}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                        <Text
                                                            as='span'
                                                            size={300}
                                                            weight='semibold'
                                                        >New</Text>
                                                    </div>
                                                </Button>
                                            </PopoverTrigger>

                                            <PopoverSurface tabIndex={-1}>
                                                {/* Create new Collection */}
                                                <CreateNewIndex hideDialog={hideDialog} />
                                                {/* not authorized message */}
                                                {!isAuthorizedToCreateIndex ? <div>You are not authorized to create a new database.</div> : null}
                                            </PopoverSurface>
                                        </Popover>
                                    </div>



                                    {/* Rename collection */}
                                    {selectedCollection ?
                                        <div className={styles.renameBtn}>
                                            <RenameCollection selectedCollection={selectedCollection}
                                                setRefreshCollection={setRefreshCollection} />
                                        </div>
                                        : ''}



                                    {/* Delete and Clear buttons */}
                                    {isAuthorizedToDeleteIndex ? (
                                        <DeleteCollection
                                            indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                            fetchCollections={fetchCollectionNames}
                                            setSelectedCollection={setSelectedCollection}
                                            setSelectedPdf={setSelectedPdf}
                                        />
                                    ) : ('')} {/* Blank if not authorized to delete */}

                                    <Divider vertical appearance='strong' />
                                    {/*refresh button*/}
                                    <Menu positioning="below-end">
                                        <MenuTrigger disableButtonEnhancement>
                                            {(triggerProps: MenuButtonProps) => (
                                                <SplitButton
                                                    appearance='subtle'
                                                    menuButton={triggerProps}
                                                    primaryActionButton={{ onClick: () => setRefreshGrid(true) }}
                                                    icon={<ArrowSync20Regular />}
                                                >
                                                    Refresh List
                                                </SplitButton>
                                            )}
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuItem persistOnClick={true}>
                                                    <Checkbox
                                                        label="Auto-refresh"
                                                        defaultChecked={autoRefresh}
                                                        onChange={() => setAutoRefresh(!autoRefresh)}
                                                    />
                                                </MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>

                                    {/*Search collection*/}
                                    <Popover withArrow open={isSearchVisible}>
                                        <PopoverTrigger disableButtonEnhancement>
                                            <Button
                                                onClick={showSearch}
                                                appearance='subtle' icon={<SearchRegular />}>
                                                Search
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverSurface tabIndex={-1}>
                                            <IconButton
                                                iconProps={{ iconName: 'Cancel' }}
                                                ariaLabel="Close"
                                                onClick={closeSearch}
                                                style={{ position: 'absolute', top: 0, right: 0 }}
                                            />
                                            <Button onClick={clearFilter}>Clear</Button>
                                            <TextField
                                                placeholder='Search...'
                                                label="Search by name"
                                                value={searchFilter}
                                                onChange={searchFilterOnChange}
                                            />
                                        </PopoverSurface>
                                    </Popover>
                                    <Divider vertical appearance='strong' />
                                    <Button icon={!showUploadArea ? <ArrowRight16Regular /> : <ArrowLeft16Regular />} onClick={() => handleShowHideUpload()} appearance='subtle'>{hideUploads ? 'Show' : 'Hide'} Upload</Button>

                                </div>
                                <div className='ms-Grid'>
                                    <div className='ms-Grid-row'>
                                        {/* Main content */}
                                        <div
                                            // className={styles.fileExplorer}
                                            className={`ms-Grid-col ms-lg9 ${styles.fileExplorer} ${hideUploads}`}
                                        >
                                            {selectedCollection ? (
                                                <>
                                                    <div className={styles.main}>
                                                        {/* Name of Collection Selected */}
                                                        <Text
                                                            as='span'
                                                            size={600}
                                                            weight='semibold'
                                                            style={{ marginBottom: '50px', color: '#00b0ba' }}
                                                        ><ArchiveRegular style={{ marginRight: '8px' }} />
                                                            {/* Show the display_name instead of the id or name */}
                                                            {selectedCollection ? selectedCollection.display_name : "Select a collection"}</Text>
                                                        <p className={styles.collectionDescription}>{selectedCollection.description}</p>
                                                        <p><FolderOpenRegular /> {selectedCollection.category}</p>

                                                        {/* Divider */}
                                                        <Divider inset appearance='strong' />


                                                        {/* size of collection & refresh button */}
                                                        <div className={styles.collectionHeader}>
                                                            {/* Display count of files*/}
                                                            <Text
                                                                size={400}
                                                                weight='semibold'
                                                                style={{ color: 'var(--primary-color)' }}
                                                            >{numberOfFilesInSelectedCollection ? numberOfFilesInSelectedCollection : "0"} files
                                                            </Text>
                                                            {/* {numberOfFailedFilesInSelectedCollection != 0 ?

                                                                <p className={styles.failedWarning}><Warning12Regular /> Some files have failed to process, retry where applicable.</p>
                                                                : ''
                                                            } */}
                                                        </div>

                                                        {/* List Files */}
                                                        <div className={styles.fileStatusContainer}>

                                                            <FileStatus
                                                                indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                                                shouldRefresh={refreshGrid}
                                                                setSelectedPdf={setSelectedPdf}
                                                                setRefreshGrid={setRefreshGrid}
                                                                setNumberOfFilesInSelectedCollection={setNumberOfFilesInSelectedCollection}
                                                                setNumberOfFailedFilesInSelectedCollection={setNumberOfFailedFilesInSelectedCollection}
                                                                autoRefresh={autoRefresh}
                                                                searchFilterText={searchFilter}
                                                                isBEadmin={isAuthorizedToViewQuickSettingsMenu}
                                                                //    uploadProgress={uploadProgress}
                                                                // setUploadProgress={setUploadProgress}
                                                                currentPageNum={currentPage}
                                                                setTotalPages={setCollectionTotalPages}
                                                            />

                                                        </div>
                                                        {!isLoading ?
                                                            <div className={styles.paginationContainer}>
                                                                <Stack horizontal tokens={{ childrenGap: 5 }} className={styles.pagination}>
                                                                    <Button
                                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                                        disabled={currentPage === 1}
                                                                        size='small'
                                                                    >Prev</Button>
                                                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                                        {renderPageButtons()}
                                                                    </Stack>
                                                                    <Button
                                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                                        disabled={currentPage === collectionTotalPages}
                                                                        size='small'
                                                                    >Next</Button>
                                                                </Stack>
                                                            </div>
                                                            : ''}


                                                    </div>

                                                </>
                                            ) : (
                                                <div className={styles.selectACollection}>
                                                    {/* Logo */}
                                                    <Image
                                                        src={BEAILogo}
                                                        alt="BEAI Logo"
                                                        height={102}
                                                    />
                                                    <Text
                                                        as='span'
                                                        size={600}
                                                    >Document Collection Manager</Text>

                                                    {/* Divider */}
                                                    <div style={{ borderTop: '1px solid var(--primary-color)', width: '80%' }} />
                                                    {/* Header Text */}

                                                    <Text
                                                        as="h1"
                                                        size={300}
                                                        weight="bold"
                                                        align="center"
                                                        style={{ color: 'var(--bold-text)' }}
                                                    >{"Create or Select a Collection to start."}
                                                    </Text>

                                                    {/* First Time graphic */}
                                                    <ArrowCircleLeft32Filled />
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={`ms-Grid-col ms-lg3 ${hideUploads}`}
                                        //className={styles.uploadSideBolton}
                                        >
                                            {/* Upload Area */}
                                            <div
                                                className={`${hideUploads}`}
                                            >
                                                <UploadArea indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                                    setRefreshGrid={setRefreshGrid}
                                                    setHasUpload={setHasUpload}
                                                    uploadProgress={uploadProgress}
                                                    setUploadProgress={setUploadProgress}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </DialogContent>

                </DialogBody>
            </DialogSurface>
        </Dialog >


    )
}
