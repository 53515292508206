// This MainTool.tsx

// We should only need to pass in the id of the quick tool this component handles all.

import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@fluentui/react'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Popover, PopoverProps, PopoverSurface, PopoverTrigger, Spinner, Tooltip } from '@fluentui/react-components'
import { Archive16Regular, ArchiveMultiple20Regular, ArchiveRegular, ArrowDownload20Regular, BoxCheckmarkFilled, CaretLeftFilled, CaretRightFilled, ChatDismissRegular, ChatRegular, ChevronRightRegular, Copy20Regular, Delete20Regular, DismissRegular, DocumentArrowLeft48Regular, DocumentPdfRegular, DocumentRegular, DocumentTextRegular, Edit16Filled, EditRegular, ErrorCircle24Regular, History20Regular, Info12Regular, Info20Filled, MoreHorizontalRegular, PreviewLinkRegular, QuestionCircle20Regular, TextWordCount20Regular, ToolboxRegular, WrenchRegular } from '@fluentui/react-icons'
import { Document, HeadingLevel, Packer, Paragraph } from 'docx'
import { saveAs } from 'file-saver'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'; // to generate a new thread_id for each chat session
import { AskResponse, chatApi, ChatRequest, ChatTurn, CollectionName, getCollectionsApi, getPromptTemplatesApi, getTemplateByIdApi, PersonaTemplate, Project, retrieveFileApi, updateProjectFieldsApi } from '../../api'
import { AnalysisPanel, AnalysisPanelTabs } from '../AnalysisPanel'
import { Answer, AnswerError, AnswerLoading } from '../Answer'
import CollectionSelector from '../CollectionSelector/CollectionSelector'
import { QuestionInput } from '../QuestionInput'
import ToolSelector from '../ToolSelector/ToolSelector'
import { UserChatMessage } from '../UserChatMessage'
import styles from './MainTool.module.css'
// tutorial system
import "@sjmc11/tourguidejs/src/scss/tour.scss"
import { TourGuideClient } from "@sjmc11/tourguidejs/src/Tour"
import { PersonaIcon } from '../PersonaIcon'
import VariableComponent from '../VariableComponent/VariableComponent'
import VariableComponentTwo from '../VariableComponentTwo/VariableComponentTwo'

// document collection tour

//tutorial setup
const tg = new TourGuideClient({ autoScroll: false }); // tutorial settings
//tutorial extra steps that don't point to components
tg.addSteps([{
    title: 'Document Collections',
    group: 'tourDocumentCollections',
    content: 'Document Collections act like folders of documents that Blue Edge AI will reference when answering your query...',
    order: 0, // Add to start
},
{
    title: 'Submitting a Query',
    group: 'tourSubmitQuery',
    content: 'A query is the task you would like to set Blue Edge AI. For example, this may be some text to analyse, or a research question to interrogate your documents...',
    order: 0, // Add to start
}])

function startTourDocumentCollections() { tg.start('tourDocumentCollections') }
function startTourSubmitQuery() { tg.start('tourSubmitQuery') }




type MainToolProps = {
    projectInfo?: Project; // project settings object
    selectedTool?: PersonaTemplate;  // template object
    fileManagerAuthorized: boolean;
    triggerCollectionRefresh: boolean;
    demoModeActive: boolean;
}

const tokenLimitReached = false // TODO add the token logic

const useStyles = makeStyles({
    dialogSurface: {
        width: '75vw',  // Adjust to your desired width
        maxWidth: '75vw',
        height: '75vh', // Adjust to your desired height
        maxHeight: '75vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});


const MainTool = ({
    projectInfo, // pass in all saved project info on load
    // selectedTool, // pass in selected persona details on load
    fileManagerAuthorized,
    triggerCollectionRefresh,
    demoModeActive
}: MainToolProps) => {
    const fuistyles = useStyles();
    const { user, getAccessTokenSilently } = useAuth0();
    const [mainTool, setMainTool] = useState<PersonaTemplate>(); // hold the tool object
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, web: boolean, run_id: string][]>([]); // hold all answers
    const [toolsUsedList, setToolsUsedList] = useState<PersonaTemplate[]>([]);// a list of the tools used in the queries submitted
    const [followupQuestions, setFollowupQuestions] = useState<string[]>([]); // follow up questions from answer
    // const [ selectedAnswer, setSelectedAnswer ] = useState<number>(0); // selected answer
    const [isChatLoading, setIsChatLoading] = useState<boolean>(false); // chat specific loading
    const lastQuestionRef = useRef<string>("");
    const [error, setError] = useState<unknown>();
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [images] = useState<string[]>([]); // state for array of images up to 5
    const [selectedCollections, setSelectedCollections] = useState<CollectionName[]>([])
    const [allCollectionNames, setAllCollectionNames] = useState<CollectionName[]>([]); // hold all names of collections

    const [threadId] = useState(uuidv4());; // generate the uuid thread_id for each chat session
    const [isLoading, setIsLoading] = useState<boolean>(false); // loading the records
    const [personasList, setPersonasList] = useState<PersonaTemplate[]>([])
    const [toolMenuDialogOpen, setToolMenuDialogOpen] = useState<boolean>(false);
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined); // state for active analysis panel tab - uses enum
    const [activeCitation, setActiveCitation] = useState<string[]>(); // which tab in the citation panel is active
    const [showCitationPanel, setShowCitationPanel] = useState(false); //citation panel show/hide
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0); // selected answer
    const [animateAnswer, setAnimateAnswer] = useState<boolean>(false);

    // State to trigger a refresh in the collection selector - true=refresh ex. setRefreshCollection(true)
    const [refreshCollection, setRefreshCollection] = useState<boolean>(false);

    // states to toggle the display of the sections
    const [hideAskAnythingSection, setHideAskAnythingSection] = useState<boolean>(false);
    const [hideDocumentCollectionSelector, setHideDocumentCollectionSelector] = useState<boolean>(false);
    const [disableDocumentCollections, setDisableDocumentCollections] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [disableVariableComponent, setDisableVariableComponent] = useState<boolean>(true);
    const [disableVaribleTwoComponent, setDisableVariableTwoComponent] = useState<boolean>(true);
    const [fixedUserQueryText, setFixedUserQueryText] = useState<string>('');

    // chat specific settings
    const [includeCompanyMemory, setIncludeCompanyMemory] = useState<boolean>(false);
    const [includeUserMemory, setIncludeUserMemory] = useState<boolean>(false);
    const [promptTemplate, setPromptTemplate] = useState<string>(''); // prompt template text
    const [temperature, setTemperature] = useState<string>('0.5'); // creativity of the AI
    const [includeWebResults, setIncludeWebResults] = useState<boolean>(true);  // Tavily web search
    const [followUpPrompt, setFollowUpPrompt] = useState<string>(''); // follow up prompt
    const [retrieveCount, setRetrieveCount] = useState<number>(10); // fallback to 10
    const [retrieveWebCount, setRetrieveWebCount] = useState<number>(3); // fallback to 1
    const [inputCharacterLimit, setInputCharacterLimit] = useState<number>(16000); // character limit for input
    const [disableFollowUpQuestions, setDisableFollowUpQuestions] = useState<boolean>(false); // disable follow up questions
    const [chatboxInput, setChatboxInput] = useState<string>(''); //the question typed into the question input box
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [originalPrompt, setOriginalPrompt] = useState<string>();
    const [useGpt4oMini, setUseGpt4oMini] = useState<boolean>(false); // use GPT-4o-mini model
    const [inputToChat, setInputToChat] = useState<string>('');
    const [disable_history, setDisableHistory] = useState<boolean>(false);
    //focus of the sections
    const [focusDocumentCollection, setFocusDocumentCollection] = useState(true);
    const [focusChatInput, setFocusChatInput] = useState(true);
    // variable component user input state
    const [variableComponentUserInput, setVariableComponentUserInput] = useState<string>('');
    const [variableComponentTwoUserInput, setVariableComponentTwoUserInput] = useState<string>('');
    const [clearChatBox, setClearChatBox] = useState<boolean>(false);
    const [variableComponentsLabels, setVariableComponentsLabels] = useState<{ title: string; text: string }[]>([]);



    //needed to stick the summary to the side
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [answersHeight, setAnswersHeight] = useState<number>(0);
    const answersRef = useRef<HTMLDivElement>(null);
    const [queryFollowupPopover, setQueryFollowupPopover] = useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
        setQueryFollowupPopover(data.open || false);

    // Check that projectInfo is not null and return to hello if it is
    const navigate = useNavigate();
    if (!projectInfo) {
        console.log("No project info passed to MainTool, redirecting to /hello");
        navigate('/hello');
        return null;
    }


    // 1. we are passing in the tool id only so we first need to fetch the whole record from the db
    const getToolRecord = async (id: string) => {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        try {
            const response = await getTemplateByIdApi(id, accessToken);
            setMainTool(response); // hold the whole tool object
        } catch (error) {
            console.error("Error fetching template:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Get collections
    const fetchCollections = async () => {
        // console.log("Fetching collections");
        const access_token = await getAccessTokenSilently();
        const allCollections = await getCollectionsApi(access_token); // Assume this returns an array of objects with collection_name
        try {
            if (allCollections) {
                setAllCollectionNames(allCollections);
                // console.log("Collections fetched: ", allCollections);
            }
        } catch (error) {
            console.error("Failed to parse selectedCollections as JSON: ", error);
        }
    };


    // Get Personas
    const loadPersonas = async () => {
        // console.log("Starting loadPersonas");
        try {
            const token = await getAccessTokenSilently();
            const personas = await getPromptTemplatesApi(token);
            setPersonasList(personas);
        } catch (error) {
            console.error('Failed to retrieve access token silently', error);
            setPersonasList([]) // blank to avoid render error
        }
    };

    // load personas on mount
    useEffect(() => {
        loadPersonas();
    }, []);


    const fetchData = async () => {
        // console.log("Starting fetchData");
        if (!projectInfo || hasLoaded) return;

        try {
            await getToolRecord(projectInfo.project_tool_id);
            if (projectInfo.project_collections !== '') {
                await fetchCollections();
            }

            if (projectInfo && firstLoad) {
                const parsedPrompts = projectInfo.prompts !== 'new' || '' ? JSON.parse(projectInfo.prompts) : [];
                const parsedCollections = projectInfo.project_collections !== 'new' || '' ? JSON.parse(projectInfo.project_collections) : [];
                const parsedToolsList = projectInfo.results !== '' ? JSON.parse(projectInfo.results) : [];
                const parsedVariableInputs = projectInfo.variableInputs !== 'new' || '' ? JSON.parse(projectInfo.variableInputs) : [];

                setAnswers(parsedPrompts);
                setSelectedCollections(parsedCollections);
                setToolsUsedList(parsedToolsList);
                setVariableComponentUserInput(parsedVariableInputs[0] || '')
                setVariableComponentTwoUserInput(parsedVariableInputs[1] || '')
            }
            if (projectInfo.project_collections !== 'new') {
                setFocusDocumentCollection(false);
                setFocusChatInput(true);
            }

            setHasLoaded(true);
            setFirstLoad(false);
            // console.log("fetchData finished");
        } catch (error) {
            console.error("Error in fetchData:", error);
        }
    };

    useEffect(() => {
        loadPersonas();
        fetchData();
    }, [projectInfo]);



    // 2. Now that we have the record we need to set the states when mainTool changes.
    useEffect(() => {
        // these toggle the sections
        setHideAskAnythingSection(mainTool?.disable_ask_anything === 'true'); // section toggle
        setHideDocumentCollectionSelector(mainTool?.disable_document_collections === 'true'); // section toggle
        setFocusChatInput(mainTool?.disable_document_collections === 'true')//if there is no document collection needed, focus the chat
        setDisableDocumentCollections(mainTool?.disable_document_collections === 'true'); // for the call to the ai
        setDisableVariableComponent(mainTool?.disable_variable_component === 'true');
        setDisableVariableTwoComponent(mainTool?.disable_variable_two_component === 'true');

        // chat specific settings
        setIncludeCompanyMemory(mainTool?.company_memory === 'true');
        setIncludeUserMemory(mainTool?.user_memory === 'true');
        setPromptTemplate(mainTool?.text ?? '');
        setFollowUpPrompt(mainTool?.follow_up_prompt ?? '');
        setRetrieveCount(Number(mainTool?.top_k ?? 1));
        setRetrieveWebCount(Number(mainTool?.web_top_k ?? 1));
        setTemperature(mainTool?.temperature ?? '0.5');
        setIncludeWebResults(mainTool?.include_web_results === 'true');
        setInputCharacterLimit(mainTool?.input_character_limit ?? 16000);
        setDisableFollowUpQuestions(mainTool?.disable_follow_up_questions === 'true');
        setUseGpt4oMini(mainTool?.use_gpt4o_mini === 'true');
        setDisableHistory(mainTool?.disable_history === 'true');
        setVariableComponentsLabels([
            { title: mainTool?.variable_component_title ?? '', text: mainTool?.variable_component_text ?? '' },
            { title: mainTool?.variable_component_two_title ?? '', text: mainTool?.variable_component_two_text ?? '' }
        ]);
        setDisableVariableComponent(mainTool?.disable_variable_component === 'true');
        setDisableVariableTwoComponent(mainTool?.disable_variable_two_component === 'true');
        setFixedUserQueryText(mainTool?.fixed_query_text || '')
        if (mainTool?.disable_document_collections === 'true') {
            setSelectedCollections([]);
        }

    }, [mainTool]); // we add mainTool to this dependency array to trigger this any time mainTool changes.


    // function to make api request
    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question; // save question as ref
        const listOfCollectionsUsed = selectedCollections ? selectedCollections.map(col => col.display_name).join(', ') : '';
        // if no query question is needed, then just put the list of collections used in
        if (hideAskAnythingSection) {
            lastQuestionRef.current = listOfCollectionsUsed;
            if (fixedUserQueryText === 'variable_1') {
                question = variableComponentUserInput
            }
            if (fixedUserQueryText === 'variable_2') {
                question = variableComponentTwoUserInput
            } else {
                question = fixedUserQueryText
            }
        }
        // clear fields
        if (error) setError(undefined);
        setIsChatLoading(true);
        setActiveAnalysisPanelTab(undefined); // close the panel if open
        //  setOpenItems([]); // close the question accordion
        setAnimateAnswer(true)
        setFirstLoad(false);

        try {
            // Testing the variable component
            // console.log("variableComponentUserInput at send: ", variableComponentUserInput[selectedAnswer]);
            // console.log("full set: " + variableComponentUserInput)
            // build history
            const history: ChatTurn[] = answers.map(a => ({
                user: [{ type: 'text', text: a[0] }],
                bot: a[1].answer
            }));

            // build image messages
            const imageMessages = images.map(url => ({
                type: 'image_url' as const, image_url: { url }
            }));

            // build request with messages
            const request: ChatRequest = {
                history: [
                    ...history,
                    {
                        user: [
                            { type: 'text', text: question },
                            ...imageMessages
                        ],
                        bot: undefined
                    }
                ],
                selectedIndexes: disableDocumentCollections ? [] : selectedCollections.map(collection => collection.collection_name),
                user: String(user?.email ?? "unknown"), // send user email if available
                threadId: String(threadId), // a uuid thread_id for each chat session
                companyMemory: Boolean(includeCompanyMemory), // boolean for company memory
                userMemory: Boolean(includeUserMemory), // boolean for user memory
                search_only_mode: Boolean(false), // Boolean(searchOnlyMode), // boolean for search only mode
                variableComponentUserInput: variableComponentUserInput, // New: variable component
                variableComponentTwoUserInput: variableComponentTwoUserInput, // New: variable 2
                // Overrides are passed to the approach
                overrides: {
                    promptTemplate: String(promptTemplate.length === 0 ? undefined : promptTemplate),
                    promptTemplateName: String(mainTool?.name ?? 'Unknown'), // pass the name of the tool for langSmith tracking purposes
                    followUpQuestionPrompt: String(followUpPrompt.length === 0 ? undefined : followUpPrompt),
                    top: Number(retrieveCount),
                    retrieveWebCount: Number(retrieveWebCount), // number of web results to return
                    disableFollowupQuestions: Boolean(disableFollowUpQuestions),
                    temperature: Number(temperature), // convert string to number
                    webResults: Boolean(includeWebResults),
                    useGpt4oMini: Boolean(useGpt4oMini),
                    disable_history: Boolean(disable_history)
                }
            };
            const accessToken = await getAccessTokenSilently(); // get token to pass to api
            const result = await chatApi(request, accessToken); // call api and pass token

            // extract the run_id from the response
            const run_id = result.run_id;

            if (hideAskAnythingSection) {
                //if no query is needed, then just put in the collection list to the question string
                setAnswers(prevAnswers => [...prevAnswers, [listOfCollectionsUsed, result, includeWebResults, run_id]]);
                if (projectInfo) {
                    updateProjectFieldsApi(
                        projectInfo.id,
                        {
                            project_name: listOfCollectionsUsed ? JSON.stringify(listOfCollectionsUsed) : 'No Name', //saves the new name
                            project_collections: selectedCollections ? JSON.stringify(selectedCollections) : '', //stringify the selected collections
                        },
                        accessToken
                    );
                } else {
                    console.error("Save Project Settings Error: could not update name");
                }
            } else {
                // Add response to array of answers
                setAnswers(prevAnswers => [...prevAnswers, [question, result, includeWebResults, run_id]]);
                if (projectInfo) {
                    updateProjectFieldsApi(
                        projectInfo.id,
                        {
                            project_name: question ? question : 'No Name', //saves the new name
                        },
                        accessToken
                    );
                    // console.log("update name: " + question);
                } else {
                    console.error("Save Project Settings Error: could not update name");
                }

            }
            // add tool used to array of tools
            if (mainTool) {
                setToolsUsedList(prevTools => [...prevTools, mainTool]);
            }

        } catch (e) {
            setError(e);
        } finally {
            setSelectedAnswer(answers.length);
            setIsChatLoading(false);
            if (answers.length === 0) {
                setQueryFollowupPopover(true); // only show the popover on the first answer
            }

        }
    };

    const saveProjectSettings = async () => {
        // console.log("Saving project settings");
        const accessToken = await getAccessTokenSilently();

        if (projectInfo) {
            // Combine the two variables into an array
            const variableInputs = [variableComponentUserInput, variableComponentTwoUserInput];

            updateProjectFieldsApi(
                projectInfo.id,
                {
                    project_description: 'auto-save', // just defaults to auto-save
                    project_tool_name: mainTool?.name, // selected tool name
                    project_tool_id: mainTool?.id, // selected tool id
                    project_collections: selectedCollections ? JSON.stringify(selectedCollections) : '', // stringify the selected collections
                    last_updated: Date(), // the date
                    prompts: answers ? JSON.stringify(answers) : '', // saved answer results
                    results: toolsUsedList ? JSON.stringify(toolsUsedList) : '', // saves the used tools list here
                    variableInputs: variableInputs ? JSON.stringify(variableInputs) : '', // add the combined inputs as a JSON string
                },
                accessToken
            );
            // console.log("Project settings saved");
        } else {
            console.error("Save Project Settings Error: no project info");
        }
    };


    useEffect(() => {
        if (!firstLoad) {
            saveProjectSettings();
        }
    }, [answers, selectedCollections]);



    // handle showing/hiding the citation panel
    const onShowCitation = (citation: string, index: number, documentID: string, sourceName: string) => {

        if (activeCitation?.[0] === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
            setShowCitationPanel(false);
        } else {
            setActiveCitation([citation, documentID, sourceName]);
            setShowCitationPanel(true); // if not expanded, this will expand the panel to display the citation
        }
        setSelectedAnswer(index);
        if (!showCitationPanel) {
            showHideCitationPanel();
        }
    };

    async function handleCitationClick(
        index: string,
        sourceName: string,
        documentId: string
    ) {
        // check for values
        if (!index || !sourceName || !documentId) {
            console.log("Error: Citation values are missing when calling handleCitationClick in answer.tsx.");
            console.log("Index:", index);
            console.log("SourceName:", sourceName);
            console.log("DocumentId:", documentId);
            return { error: true, message: "An error occurred" };
        }
        const token = await getAccessTokenSilently();
        // Order: index_name, document_id, filename, accessToken
        try {
            const fileUrl = await retrieveFileApi(index, documentId, sourceName, token);
            onShowCitation(fileUrl, selectedAnswer, documentId, sourceName);
        } catch (error) {
            console.error("Error retrieving file from API", error);
            alert("Error retrieving file from API");
        }
    };

    // Toggle citation panel tab
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            // setActiveAnalysisPanelTab(tab);
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);
        if (!showCitationPanel) {
            showHideCitationPanel();
        }
    };

    //open and close the citation panel
    const showHideCitationPanel = () => {
        if (showCitationPanel) {
            setShowCitationPanel(false);
        } else {
            setShowCitationPanel(true);
        }
    }

    // return word count
    function wordCount(text: string) {
        const mainAnswer = text.replace(/\[.*?\]/g, '').replace(/<<.*?>>/g, '').trim();
        const mainAnswerCounted = mainAnswer.split(/\s+/).length;
        return mainAnswerCounted
    };

    //button to edit document collections
    const handleChangeDocumentCollections = () => {
        setFocusDocumentCollection(true);
        setFocusChatInput(false);

    }

    //button to confirm selected collections
    const handleConfirmDocumentCollections = () => {
        if (hideAskAnythingSection && (!disableVariableComponent && !disableVaribleTwoComponent)) {
            makeApiRequest('')
        } else {
            setFocusDocumentCollection(false);
            setFocusChatInput(true);
            saveProjectSettings();
        }
    }

    //button to confirm selected collections & re run question - not currently used
    // const handleConfirmDocumentCollectionsAndReRun = () => {
    //     makeApiRequest(lastQuestionRef.current)
    //     setFocusDocumentCollection(false);
    //     setFocusChatInput(false);
    // }

    // handle a tool Change
    const handleToolChange = async (persona: PersonaTemplate) => {
        setMainTool(persona);
        handleOpenToolMenu();
        setFocusDocumentCollection(true);
        setFocusChatInput(false);
        setFollowupQuestions([]);
        setInputToChat('');
        setInputCharacterLimit(persona.input_character_limit || 86600);
    }

    const handleOpenToolMenu = () => {
        if (toolMenuDialogOpen) {
            setToolMenuDialogOpen(false)
        } else {
            setToolMenuDialogOpen(true)

        }
    }

    const handleDisplayAnswer = (newAnswer: number) => {
        setSelectedAnswer(newAnswer);
        setAnimateAnswer(false);
        setFollowupQuestions([]);
    }

    function copyToClipboard(divId: string): void {
        const divElement = document.getElementById(divId);
        if (divElement) {
            const text = divElement.innerText; // Plain text
            const html = divElement.innerHTML; // HTML content

            const textBlob = new Blob([text], { type: 'text/plain' });
            const htmlBlob = new Blob([html], { type: 'text/html' });

            const clipboardItem = new ClipboardItem({
                'text/plain': textBlob,
                'text/html': htmlBlob
            });

            try {
                navigator.clipboard.write([clipboardItem]);
            } catch (err) {
                console.error('Failed to copy content: ', err);
            }
        } else {
            console.error(`Element with id ${divId} not found.`);
        }
    }



    // Create a word document
    function createWordDoc(filename: string) {
        const mainAnswer = answers[selectedAnswer][1].answer.replace(/<<.*?>>/g, '')     // Remove text between << >>
            .replace(/\*\*/g, '')        // Remove double asterisks
            .replace(/\[.*?\]/g, '')     // Remove text between square brackets including brackets
            .replace(/\s+\./g, '.')      // Remove extra spaces before fullstops
            .replace(/#{2,}/g, '')       // Remove 2 or more consecutive # characters
            .trim();
        //   console.log(mainAnswer);
        const answerSplitText = mainAnswer.split(/\r?\n/);
        // Create a new Document
        const buildParagraph = () => {
            const paragraphArray = [];
            for (let i = 0; i < answerSplitText.length; i++) {
                paragraphArray.push(new Paragraph({ text: answerSplitText[i] }));
            }
            return paragraphArray;
        };

        const doc = new Document({
            creator: "Blue Edge AI",
            title: "Blue Edge AI Answer",
            styles: {
                default: {
                    document: {
                        run: {
                            size: "11pt",
                            font: "Calibri",
                        },
                    },
                }
            },
            sections: [
                {

                    children: [
                        new Paragraph({ text: 'Blue Edge AI', heading: HeadingLevel.HEADING_1 }),
                        ...buildParagraph(), // paragraphs are not coming through
                    ],
                },
            ],
        });



        // Pack the Document
        Packer.toBlob(doc)
            .then((blob) => {
                // saveAs from FileSaver will download the file
                saveAs(blob, filename + ".docx");
            })
            .catch((error) => {
                console.error("Error generating the document:", error);
                // Handle the error appropriately here
            });
    }

    //remove an answer
    const removeAnswerByIndex = (index: number) => {
        setAnswers(prevAnswers => {
            if (index < 0 || index >= prevAnswers.length) {
                throw new Error("Index out of bounds");
            }
            setSelectedAnswer(0);
            return [...prevAnswers.slice(0, index), ...prevAnswers.slice(index + 1)];
        });
    }

    const handleCopyToChatBox = async (input: string) => {
        try {
            const trimInput = input
                .replace(/<<.*?>>/g, '')     // Remove text between << >>
                .replace(/\*\*/g, '')        // Remove double asterisks
                .replace(/\[.*?\]/g, '')     // Remove text between square brackets including brackets
                .replace(/\s+\./g, '.')      // Remove extra spaces before fullstops
                .replace(/#{2,}/g, '')       // Remove 2 or more consecutive # characters
                .trim();
            setInputToChat(chatboxInput + trimInput);
        } catch (error) {
            console.error(error);
        }
    }

    //clear after use
    useEffect(() => {
        setInputToChat('');
    }, [inputToChat])

    const handleEditReuseQuery = () => {
        if (hideAskAnythingSection) {
            setFocusDocumentCollection(true);
        } else {
            setFocusDocumentCollection(false);
            setFocusChatInput(true);
            setInputToChat(answers[selectedAnswer][0]);
            // console.log(answers[ selectedAnswer ][ 0 ]);
        }
    }

    //refresh the collection list on trigger
    useEffect(() => {
        setRefreshCollection(triggerCollectionRefresh);
    }, [triggerCollectionRefresh]);


    if (isLoading) {
        return <div><Spinner /></div>;
    }

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.noPadding} 'ms-Grid'`}>
                {/* Left Panel */}
                <div className={`${styles.animateWidth} ${answers.length !== 0 || isChatLoading ? 'ms-Grid-col ms-lg7' : styles.fullWidth}`}>
                    <div className={styles.settingsPanel}>
                        <div className={styles.sectionFull}>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-lg1" >

                                </div>
                                <div className={styles.sectionDetails}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg3" >

                                            <h2 className={styles.sectionName} ><WrenchRegular /> AI Tool</h2>

                                            <Dialog open={toolMenuDialogOpen}>
                                                <DialogTrigger disableButtonEnhancement>
                                                    <Button icon={<ToolboxRegular />} appearance='primary' onClick={() => handleOpenToolMenu()}>Change AI Tool</Button>
                                                </DialogTrigger>
                                                <DialogSurface>
                                                    <DialogBody>
                                                        <DialogTitle>AI Tools</DialogTitle>
                                                        <DialogContent>
                                                            <ToolSelector
                                                                personasList={personasList}
                                                                handleToolSelect={handleToolChange}
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button appearance="secondary" onClick={() => handleOpenToolMenu()}>Close</Button>
                                                        </DialogActions>
                                                    </DialogBody>
                                                </DialogSurface>
                                            </Dialog>
                                        </div>
                                        <div className="ms-Grid-col ms-lg9" >

                                            {mainTool ?
                                                <div className={styles.mainToolInfo}>
                                                    <div className={styles.toolIcon}>
                                                        <PersonaIcon
                                                            iconName={mainTool?.iconName}
                                                            iconColor={mainTool?.color} // use category color instead of the tool color
                                                        />
                                                    </div>
                                                    <div>
                                                        <h3 style={{ color: mainTool?.color }}>{mainTool?.name}</h3>
                                                        <p
                                                            className={styles.mainToolDescription}
                                                            dangerouslySetInnerHTML={{ __html: mainTool?.long_description || '' }}
                                                        />
                                                    </div>
                                                </div> :
                                                <p>Select a tool to start.</p>}
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div className={styles.sectionFull}>
                            {/* Document collection section */}
                            {!hideDocumentCollectionSelector ?
                                <div className="ms-Grid" id='DocumentCollections'>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-lg1" >
                                            <div className={styles.sectionNumber}>
                                                {/* <ArchiveRegular /> */}
                                                <p>1</p>
                                            </div>
                                        </div>
                                        {focusDocumentCollection ?

                                            <div className="ms-Grid-col ms-lg11">
                                                <div className={styles.sectionDetails}>
                                                    <h2 className={styles.sectionName} ><ArchiveRegular /> Select your collections</h2>
                                                    <Button className={styles.showMeButton} icon={<QuestionCircle20Regular />} appearance='subtle' onClick={() => startTourDocumentCollections()}>Show me</Button>
                                                    {/* Render the document collections description or a default */}
                                                    <div className={styles.sectionDescription}>
                                                        {mainTool?.document_collections_description ? (
                                                            <div dangerouslySetInnerHTML={{ __html: mainTool.document_collections_description }} />
                                                        ) : (
                                                            <>
                                                                <p>
                                                                    Document Collections are "folders" of documents that Blue Edge AI will reference to generate your results.
                                                                </p>
                                                                <br />
                                                                <p>
                                                                    To get the best output, you should select the most relevant Document Collections for the AI to reference, too many and you will dilute your results.
                                                                </p>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className={styles.collectionSelectorContainer}>
                                                        <CollectionSelector
                                                            selectedCollections={selectedCollections}
                                                            setSelectedCollections={setSelectedCollections}
                                                            refreshCollection={refreshCollection}
                                                            setRefreshCollection={setRefreshCollection}
                                                            demoModeActive={demoModeActive}
                                                        />
                                                    </div>
                                                    <div className={styles.confirmButtonsContainer}>
                                                        <p>If you don't see the collection you require, {fileManagerAuthorized ? 'use the Collection Manager to create/edit your collections (top of the page).' : 'contact your file admin to create/edit collections.'}</p>
                                                        <Button icon={<BoxCheckmarkFilled />} appearance='primary' disabled={selectedCollections.length == 0} onClick={() => handleConfirmDocumentCollections()} className={styles.confirmButton}
                                                            data-tg-tour="When you are happy with your selection, click Confirm Selection."
                                                            data-tg-title="Confirm Selection"
                                                            data-tg-group="tourDocumentCollections"
                                                            data-tg-order="4"
                                                        >Confirm Selection                                     {hideAskAnythingSection && (!disableVariableComponent && !disableVaribleTwoComponent) ? '& Run Query' : ''}
                                                        </Button>


                                                    </div>

                                                </div>


                                            </div>
                                            :
                                            <div className="ms-Grid-col ms-lg11">
                                                <div className={styles.sectionDetails}>
                                                    <h2 className={styles.sectionName} ><ArchiveRegular /> Select your collections</h2>
                                                    {mainTool ?
                                                        <>
                                                            {/* List selected Collections */}
                                                            <div className={styles.summaryContainer}>
                                                                <h3 className={styles.summaryHeading}>Selected Collections:</h3>
                                                                {/* List selected */}
                                                                <ol>
                                                                    {selectedCollections!.map((collection, index) => (
                                                                        <li key={index}><Archive16Regular /> {collection.display_name}</li>
                                                                    ))}
                                                                </ol>
                                                            </div>
                                                            <Button appearance='subtle' className={styles.changeButton} onClick={() => handleChangeDocumentCollections()} icon={<EditRegular />}>Change selection</Button>
                                                        </> :
                                                        'You must a AI Tool first.'
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>


                                </div>
                                : ''
                            }
                        </div>



                        {/* Ask anything section */}
                        {!hideAskAnythingSection ? (

                            <div className={styles.sectionFull}>

                                {/* We can list out the sub components below with simple toggles to display each one*/}
                                <div className="ms-Grid" id='Query'>
                                    <div className="ms-Grid-row" >
                                        {/* left column */}
                                        <div className="ms-Grid-col ms-lg1">
                                            <div className={styles.sectionNumber} >{hideDocumentCollectionSelector ? '1' : '2'}</div>
                                        </div>
                                        {focusChatInput ?
                                            <>
                                                {!hideAskAnythingSection ? (
                                                    <div className="ms-Grid-col ms-lg11">
                                                        <div className={`${styles.sectionDetails} ${styles.last}`}>
                                                            <h2 className={styles.sectionName} ><ChatRegular />{answers.length != 0 ? ' Continue your queries' : ' Submit your query'}
                                                                <Popover withArrow open={queryFollowupPopover} appearance='brand' onOpenChange={handleOpenChange}>
                                                                    <PopoverTrigger disableButtonEnhancement><Button appearance='transparent' icon={<Info12Regular />} /></PopoverTrigger>
                                                                    <PopoverSurface tabIndex={-1} className={styles.followupQueryPopover}>
                                                                        <h3><Info20Filled /> Let's continue...</h3>
                                                                        <p>You can now submit new queries or ask<br></br> follow-up questions about your previous results.</p>
                                                                    </PopoverSurface>
                                                                </Popover></h2>
                                                            <Button className={styles.showMeButton} icon={<QuestionCircle20Regular />} appearance='subtle' onClick={() => startTourSubmitQuery()}>Show me</Button>

                                                            <div className={styles.sectionDescription}>
                                                                {mainTool?.ask_anything_description ? (
                                                                    <div dangerouslySetInnerHTML={{ __html: mainTool.ask_anything_description }} />
                                                                ) : (
                                                                    <>
                                                                        <p>Now it's time to ask the AI what you'd like to do. The best prompts for the AI have as much context as possible.</p>
                                                                        <p>Provide detailed information and be specific to get the most accurate and helpful responses.</p>
                                                                    </>
                                                                )}
                                                            </div>

                                                            {/* Variable component */}
                                                            {variableComponentsLabels.length != 0 && (disableVariableComponent || disableVaribleTwoComponent)
                                                                ?
                                                                <div className={styles.variableInputArea}>
                                                                    {disableVariableComponent ? (
                                                                        <VariableComponent
                                                                            setVariableComponentUserInput={setVariableComponentUserInput}
                                                                            variableComponentText={variableComponentsLabels[0]}
                                                                            variableInputText={variableComponentUserInput}
                                                                            disableVar={isChatLoading}
                                                                            saveLastUsed={variableComponentUserInput}
                                                                        />
                                                                    ) : ''}

                                                                    {/* Variable component two */}
                                                                    {disableVaribleTwoComponent ? (
                                                                        <VariableComponentTwo
                                                                            setVariableComponentTwoUserInput={setVariableComponentTwoUserInput}
                                                                            variableComponentTwoText={variableComponentsLabels[1]}
                                                                            variableInputTwoText={variableComponentTwoUserInput}
                                                                            disableVar={isChatLoading}
                                                                            saveLastUsedTwo={variableComponentTwoUserInput}
                                                                        />
                                                                    ) : ''}
                                                                </div>
                                                                : ''}

                                                            <div className={styles.chatInputContainer}>
                                                                {tokenLimitReached ?
                                                                    <div className={styles.tokenLimitReached}>
                                                                        <ErrorCircle24Regular />

                                                                        <p>You have used your credit allowance.</p>

                                                                        <br />

                                                                        <p>Please contact your Super Admin to resolve this issue.</p>
                                                                    </div> : (
                                                                        <>
                                                                            {/* Follow up questions */}
                                                                            {answers.length !== 0 ? (
                                                                                <>
                                                                                    <div className={styles.questionInput}>
                                                                                        <div className={styles.questionInputTools}>
                                                                                            <Tooltip
                                                                                                withArrow
                                                                                                content="Click to insert your last used query"
                                                                                                relationship="description"
                                                                                            >
                                                                                                <Button icon={<History20Regular />} appearance='secondary'
                                                                                                    onClick={() => handleCopyToChatBox(lastQuestionRef.current)}
                                                                                                    disabled={isChatLoading}
                                                                                                >
                                                                                                    <span className={styles.queryHistoryButton}
                                                                                                    >
                                                                                                        Last Query: {lastQuestionRef.current}</span>
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                            <Menu>
                                                                                                <MenuTrigger disableButtonEnhancement>
                                                                                                    <Button icon={<MoreHorizontalRegular />}
                                                                                                        appearance='primary' disabled={isChatLoading} />
                                                                                                </MenuTrigger>

                                                                                                <MenuPopover>
                                                                                                    <MenuList>
                                                                                                        <MenuItem onClick={() => handleCopyToChatBox(answers[selectedAnswer][1].answer)} icon={<DocumentArrowLeft48Regular />}
                                                                                                            disabled={isChatLoading}
                                                                                                        >Insert text from query result</MenuItem>
                                                                                                        <MenuItem onClick={() => handleCopyToChatBox(answers[selectedAnswer][0])} icon={<EditRegular />}>Reuse Query {selectedAnswer + 1}</MenuItem>
                                                                                                        <MenuItem icon={<ChatDismissRegular />} onClick={() => setClearChatBox(!clearChatBox)}>Clear Query</MenuItem>
                                                                                                    </MenuList>
                                                                                                </MenuPopover>
                                                                                            </Menu>


                                                                                        </div>
                                                                                        <QuestionInput
                                                                                            clearOnSend
                                                                                            placeholder={"Ask here..."}
                                                                                            disabled={isChatLoading}
                                                                                            onSend={question => makeApiRequest(question)}
                                                                                            setInputLength={() => { }} // pass setter for state to track number of characters
                                                                                            maxInputLength={inputCharacterLimit}
                                                                                            chatBoxValue={inputToChat}
                                                                                            followupQuestions={followupQuestions}
                                                                                            setChatboxInput={setChatboxInput}
                                                                                            clearChatBox={clearChatBox}
                                                                                        />

                                                                                    </div>


                                                                                </>
                                                                            ) : (
                                                                                <div className={styles.chatInput}>
                                                                                    <QuestionInput
                                                                                        clearOnSend
                                                                                        placeholder={"Ask here..."}
                                                                                        disabled={isChatLoading}
                                                                                        onSend={question => makeApiRequest(question)}
                                                                                        setInputLength={() => { }} // sets the length of text input
                                                                                        maxInputLength={inputCharacterLimit}
                                                                                        originalPrompt={originalPrompt} // optional pass in default prompt
                                                                                        chatBoxValue=''
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''}
                                            </>
                                            :
                                            <div className="ms-Grid-col ms-lg11">
                                                <div className={`${styles.sectionDetails} ${styles.last}`}>
                                                    <h2 className={styles.sectionName} ><ChatRegular /> Submit your query</h2>
                                                    <p>You must select relevant Document Collections first.</p>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className={styles.spacer} />
                                <div className={styles.spacer} />



                                {/* TESTING SETTINGS
                                <div className={styles.saveCloseButtons}>
                                    <Button icon={<ArrowLeftRegular />} onClick={() => saveProjectSettings()}>Save Test</Button>
                                    <p>selected answer test</p>
                                    <input type='number' value={selectedAnswer} onChange={e => setSelectedAnswer(e.target.valueAsNumber)}></input>
                                </div> */}
                            </div>
                        ) : ''}

                        {hideAskAnythingSection && (disableVariableComponent || disableVaribleTwoComponent) ?

                            <div className={styles.sectionFull}>

                                <div className="ms-Grid" id='Query'>
                                    <div className="ms-Grid-row" >
                                        {/* left column */}
                                        <div className="ms-Grid-col ms-lg1">
                                            <div className={styles.sectionNumber} >2</div>
                                        </div>

                                        <div className="ms-Grid-col ms-lg11">
                                            <div className={`${styles.sectionDetails} ${styles.last}`}>
                                                <h2 className={styles.sectionName} ><ChatRegular />
                                                    Query Options</h2>

                                                {/* Variable component */}
                                                {variableComponentsLabels.length != 0 && (disableVariableComponent || disableVaribleTwoComponent)
                                                    ?
                                                    <div>
                                                        {disableVariableComponent ? (
                                                            <VariableComponent
                                                                setVariableComponentUserInput={setVariableComponentUserInput}
                                                                variableComponentText={variableComponentsLabels[0]}
                                                                variableInputText={variableComponentUserInput}
                                                                disableVar={isChatLoading}
                                                                saveLastUsed={variableComponentUserInput}
                                                            />
                                                        ) : ''}

                                                        {/* Variable component two */}
                                                        {disableVaribleTwoComponent ? (
                                                            <VariableComponentTwo
                                                                setVariableComponentTwoUserInput={setVariableComponentTwoUserInput}
                                                                variableComponentTwoText={variableComponentsLabels[1]}
                                                                variableInputTwoText={variableComponentTwoUserInput}
                                                                disableVar={isChatLoading}
                                                                saveLastUsedTwo={variableComponentTwoUserInput}
                                                            />
                                                        ) : ''}
                                                    </div>
                                                    : ''}
                                                <div className={styles.confirmButtonsContainer}>

                                                    <Button appearance='primary' iconPosition='after' icon={<ChevronRightRegular />} onClick={() => makeApiRequest('')} disabled={isChatLoading || selectedCollections.length === 0}>Submit Query</Button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            : ' '};

                        <div >
                            <Dialog
                                open={showCitationPanel}
                                inertTrapFocus={true}
                            >
                                <DialogSurface className={fuistyles.dialogSurface}>
                                    <DialogBody>
                                        <DialogTitle>
                                            Source Analyser
                                            <DialogActions>
                                                <DialogTrigger disableButtonEnhancement>
                                                    <Button className={styles.sourceCloseBtn} appearance="primary" icon={<DismissRegular />} onClick={showHideCitationPanel}></Button>
                                                </DialogTrigger>
                                            </DialogActions>
                                        </DialogTitle>
                                        <DialogContent>
                                            {answers.length > 0 && !isLoading ? (
                                                <AnalysisPanel
                                                    className={styles.chatAnalysisPanel}
                                                    activeCitation={activeCitation}
                                                    onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                                    answer={answers[selectedAnswer][1]}
                                                    settingsButtonVisible={true}
                                                />
                                            ) : (<p>Citations will be displayed here.</p>)}
                                        </DialogContent>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                        </div>
                    </div>
                </div>

                <div className={`${styles.animateWidth} ${answers.length !== 0 || isChatLoading ? 'ms-Grid-col ms-lg5' : styles.hideWidth}`}>
                    {/* Results area*/}

                    <div ref={answersRef} className={styles.resultsPanel}>
                        {answers.length != 0 || isChatLoading ?
                            <div className={styles.chatPanel}
                                data-tg-tour="Your query results will appear here on the right hand side..."
                                data-tg-title="Results"
                                data-tg-group="tourSubmitQuery"
                                data-tg-order="6">
                                <div className={styles.chatMessageStream}>
                                    <div className={styles.answerItem}>
                                        <div
                                            className={styles.answerHeader}
                                        >
                                            {/* <div className={styles.answerTool}>
                                                {answers[selectedAnswer][1].prompt_template}
                                            </div> */}

                                            {isChatLoading ?
                                                <span className={styles.answerHeaderText}><strong>Query:</strong> {lastQuestionRef.current}</span>
                                                :
                                                <div>
                                                    {hideAskAnythingSection ? '' :
                                                        <Tooltip content='Edit query and re-use.' relationship='label'>
                                                            <Button icon={<Edit16Filled />} size='small' appearance='transparent' className={styles.editAnswerBtn} onClick={() => handleEditReuseQuery()} />
                                                        </Tooltip>
                                                    }
                                                    <span className={styles.answerHeaderText} title={answers[selectedAnswer][0]}><strong>Query:</strong> {answers[selectedAnswer][0]}</span>

                                                    <div className={styles.answerToolUsed} style={{ color: toolsUsedList?.[selectedAnswer].color }}>
                                                        <PersonaIcon
                                                            iconName={toolsUsedList?.[selectedAnswer].iconName}
                                                            iconColor={toolsUsedList?.[selectedAnswer].color} // use category color instead of the tool color
                                                        />
                                                        {toolsUsedList?.[selectedAnswer].name}
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                        <div className={styles.answerToolBar}
                                            data-tg-tour="Using the tools here, you can copy, download as a word document, or delete the query result..."
                                            data-tg-title="Using the result"
                                            data-tg-group="tourSubmitQuery"
                                            data-tg-order="7">
                                            <div className={styles.answerSelector}
                                                data-tg-tour="As you ask multiple queries, you can navigate through each result here..."
                                                data-tg-title="History"
                                                data-tg-group="tourSubmitQuery"
                                                data-tg-order="8">
                                                {isChatLoading ? 'Writing...' :
                                                    <>
                                                        <Button disabled={selectedAnswer == 0} icon={<CaretLeftFilled />} appearance='transparent' onClick={() => handleDisplayAnswer(selectedAnswer - 1)} />
                                                        <strong>Query {selectedAnswer + 1}</strong> of {answers.length == 0 ? '1' : answers.length}
                                                        <Button icon={<CaretRightFilled />} appearance='transparent' disabled={selectedAnswer + 1 == answers.length || isChatLoading} onClick={() => handleDisplayAnswer(selectedAnswer + 1)} />
                                                    </>
                                                }

                                            </div>
                                            <Button color='white' icon={<Copy20Regular />} appearance='transparent' onClick={() => copyToClipboard("TheAnswer")} title='Copy Results to Clipboard' disabled={isChatLoading}></Button>

                                            <Button color='white' icon={<ArrowDownload20Regular />} appearance='transparent' onClick={() => createWordDoc("Blue Edge AI Results")} title='Download as Word Document' disabled={isChatLoading}></Button>

                                            <Divider vertical inset appearance='strong' />
                                            <Button color='white' icon={<Delete20Regular />} appearance='transparent' onClick={() => removeAnswerByIndex(selectedAnswer)} title='Remove from queries' disabled={isChatLoading}>Delete</Button>
                                        </div>

                                        <div className={styles.answerBodyText}>
                                            {/* Answer */}

                                            {/* answer loading indicator */}
                                            {isChatLoading ? (
                                                <>
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </>
                                            ) :
                                                <Answer
                                                    answer={answers[selectedAnswer][1]}
                                                    isSelected={true} // boolean
                                                    onCitationClicked={(filePath, documentID, sourceName) => onShowCitation(filePath, selectedAnswer, documentID, sourceName)}
                                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, selectedAnswer)}
                                                    onSupportingContentClicked={() => { }}
                                                    onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                    selectedIndexes={selectedCollections} // array of strings of id's of collections
                                                    settingsButtonVisible={true} // boolean
                                                    webResultsIncluded={false} // boolean
                                                    showFollowupQuestions={true} // boolean
                                                    exportFollowupQuestions={setFollowupQuestions}
                                                    animate={animateAnswer}
                                                />
                                            }
                                        </div>
                                        <div className={styles.answerFooter}>
                                            {isChatLoading ? 'Writing...'
                                                :
                                                <>
                                                    <div className={styles.answerStats}
                                                        data-tg-tour="The result word count and sources used to generate an answer will display here..."
                                                        data-tg-title="Stats"
                                                        data-tg-group="tourSubmitQuery"
                                                        data-tg-order="9">
                                                        <div className={styles.wordCounter}><TextWordCount20Regular /> {wordCount(answers[selectedAnswer][1].answer)} words</div>
                                                        {answers[selectedAnswer][1].data_points.length != 0 ?
                                                            <Popover withArrow>
                                                                <PopoverTrigger disableButtonEnhancement>
                                                                    <Button color='white' appearance='transparent' icon={<ArchiveMultiple20Regular />} title='View all sources used to create this answer'> {answers[selectedAnswer][1].data_points.length} sources used</Button>
                                                                </PopoverTrigger>

                                                                <PopoverSurface tabIndex={-1} className={styles.sourcesPopover}>
                                                                    <p>Select a source to show more information</p>
                                                                    <div className={styles.sources}>
                                                                        <Stack.Item>
                                                                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                                                {/* Map out the data points object */}
                                                                                {answers[selectedAnswer][1].data_points.map((dp, index) => {
                                                                                    const fileExtensionIndex = dp.sourceName.lastIndexOf('.');
                                                                                    let sourceName = dp.sourceName;
                                                                                    let fileExtension;
                                                                                    if (fileExtensionIndex != -1) {
                                                                                        sourceName = dp.sourceName.substring(0, fileExtensionIndex);
                                                                                        fileExtension = dp.sourceName.split('.').pop();
                                                                                    }

                                                                                    // Search allCollectionNames for the matching dp.index and set that as the display name
                                                                                    const collection = allCollectionNames.find(collection => collection.collection_name === dp.index);
                                                                                    const displayName = collection ? collection.display_name : dp.index;


                                                                                    return (
                                                                                        <div key={index} className={styles.sourcesItem} title={`${dp.sourceName} - ${index}`}
                                                                                            onClick={() => handleCitationClick(dp.index, dp.sourceName, dp.documentId)}>
                                                                                            <div className={styles.sourcesIcon}>
                                                                                                {fileExtension == "pdf" ? <DocumentPdfRegular style={{ color: "#FF0000" }} className={styles.icon} /> : fileExtension == "docx" || fileExtension == "doc" ? <DocumentTextRegular style={{ color: "#295ba9" }} className={styles.icon} /> : fileExtension == "url" ? <PreviewLinkRegular className={styles.icon} /> : <DocumentRegular className={styles.icon} />}
                                                                                            </div>

                                                                                            <p className={styles.sourceName}>{sourceName}</p>
                                                                                            <p className={styles.sourceCollection}>                                            <ArchiveRegular style={{ display: "inline-block" }} /> {displayName}</p>

                                                                                        </div>
                                                                                    )

                                                                                })}
                                                                            </Stack>
                                                                        </Stack.Item>
                                                                    </div>

                                                                </PopoverSurface>
                                                            </Popover>
                                                            : ''}
                                                    </div>
                                                    <div className={styles.AnswerFooterButtons}>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>


                                    {error ? (
                                        // Error Message
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                            </div>
                                        </>
                                    ) : null}
                                    <div ref={chatMessageStreamEnd} />

                                </div>
                            </div>
                            : ''
                        }

                    </div>

                </div>

            </div >
        </div >
    )
}

export default MainTool