// This component will have the upload button to select files, a react drop zone, then an area to view progress of each file.
import { useAuth0 } from '@auth0/auth0-react';
import { List } from '@fluentui/react';
import { Divider, Spinner, Text, Button, Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, ProgressBar, Field, Toaster, useToastController, Toast, ToastTitle, ToastBody, useId, ToastIntent, ToastTrigger, Link, DialogTrigger, DialogBody } from '@fluentui/react-components';
import { Checkmark16Regular, CloudArrowUp24Regular, CloudCheckmark24Regular, DocumentError20Regular, InfoRegular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFileApi } from '../../api';
import { IUploadResponse } from '../../api/models';
import styles from './UploadArea.module.css';
import UploadUrl from '../UploadUrl/UploadUrl';

type Props = {
    indexName: string;
    setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>; // true = trigger refresh in file status list
    setHasUpload: React.Dispatch<React.SetStateAction<boolean>>;
    uploadProgress: Map<string, number>;
    setUploadProgress: React.Dispatch<React.SetStateAction<Map<string, number>>>;
    // setUploadFiles: React.Dispatch<File[]>;
}


const UploadArea = ({ indexName,
    setRefreshGrid,
    setHasUpload,
    uploadProgress,
    setUploadProgress,
    //   setUploadFiles
}: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [uploadComplete, isUploadComplete] = React.useState(false); // show the close button once all files are done
    const [files, setFiles] = React.useState<File[]>([]);// state to store the list of files to be uploaded
    const [uploadedDocsNumber, setUploadedDocsNumber] = useState(0)//uploaded docs number - count
    const [dropErrorMessage, setDropErrorMessage] = useState<string[]>([]);// error message for file drop
    const [uploadResponses, setUploadResponses] = useState<Map<string, { message: string, documentId: string, index: number }>>(new Map());
    const [uploadErrors, setUploadErrors] = useState<Array<{ fileName: string, error: string }>>([]);
    const [showUploadList, setShowUploadList] = useState(false);
    const [isRejectedDialogOpen, setIsRejectedDialogOpen] = useState(false); // rejected files dialog
    const [doneCountdown, setDoneCountdown] = useState<number>(6);
    const [isRunning, setIsRunning] = useState<boolean>(false);

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // Define a notify to display the Toast messages
    const notify = (
        title: string = 'Success!', // default
        body: string = 'The operation was successful.',
        message_intent: ToastIntent = "success"
    ) =>
        dispatchToast(
            // Use the Toast component to display a message
            <Toast
                as='div'
                appearance='inverted' // dark mode
                key={undefined}

            >
                <ToastTitle>{title}</ToastTitle>
                <ToastBody><Text>{body}</Text></ToastBody>
                <ToastTrigger>
                    <Link>Dismiss</Link>
                </ToastTrigger>
            </Toast>,
            { timeout: message_intent == 'error' ? 30000 : 5000, intent: message_intent, pauseOnHover: true }
        );

    //finished uploading an start a countdown to clear the list
    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (isRunning && doneCountdown > 0) {
            timer = setTimeout(() => {
                setDoneCountdown((prevCount) => prevCount - 1);
            }, 1000);
        } else if (isRunning && doneCountdown === 0) {
            ClearUploadList();
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [isRunning, doneCountdown]);

    const startCountdown = () => {
        setDoneCountdown(20); //this is the number in seconds to have a countdown timer for
        setIsRunning(true);
    };

    // Open the dialog if there are error messages
    useEffect(() => {
        if (dropErrorMessage && dropErrorMessage.length > 0) {
            setIsRejectedDialogOpen(true);
            console.log('Open dialog')
        }
    }, [dropErrorMessage]); // This effect depends on dropErrorMessage

    const toggleDialog = () => {
        setIsRejectedDialogOpen(false);
        setDropErrorMessage([]); // Clear error messages when closing the dialog
    };

    // show close button when upload is complete
    function activateUploadCloseBtn() {
        isUploadComplete(true);
        setHasUpload(true);
    }

    // clear the files and upload progress when the component is unmounted
    useEffect(() => {
        // This function will be called when the component is unmounted
        return () => {
            setFiles([]);
            setUploadProgress(new Map());
            setUploadResponses(new Map());
        };
    }, []);



    const onDrop = async (acceptedFiles: File[]) => {
        const token = await getAccessTokenSilently();
        let counter = 1;
        isUploadComplete(false);
        setHasUpload(true);
        setFiles([...acceptedFiles]);
        setUploadedDocsNumber(counter);
        const initialUploadProgress = new Map();
        acceptedFiles.forEach(file => initialUploadProgress.set(file.name, 0));
        setUploadProgress(initialUploadProgress);

        // Clear error list before starting new uploads
        setUploadErrors([]);

        const uploadPromises = acceptedFiles.map(async (file) => {
            try {
                const formData = new FormData();
                formData.append('files', file);
                const request: FormData = formData;
                const response: IUploadResponse = await uploadFileApi(request, indexName, token, (progressEvent) => {
                    if (progressEvent.total) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        percentCompleted = percentCompleted >= 100 ? 90 : percentCompleted;
                        setUploadProgress(prev => new Map(prev).set(file.name, percentCompleted));
                    }
                });

                const responseObj = JSON.parse(response.success);
                const responseDetails = {
                    message: responseObj.message,
                    documentId: responseObj.documentId,
                    index: responseObj.index
                };

                setUploadProgress(prev => new Map(prev).set(file.name, 100));
                setUploadedDocsNumber(counter++);
                setUploadResponses(prev => new Map(prev.set(file.name, responseDetails)));
            } catch (error) {
                console.error(`Error uploading file ${file.name}: `, error);
                // Add error to the list
                setUploadErrors(prev => [...prev, {
                    fileName: file.name,
                    error: error instanceof Error ? error.message : 'Unknown error'
                }]);

                notify(
                    'Error uploading file, Please try again.',
                    `${file.name}: ${error instanceof Error ? error.message : 'Unknown error'}`,
                    'error'
                );
            }
        });

        Promise.all(uploadPromises)
            .then(() => setRefreshGrid(true))
            .then(() => {
                // Check if there were any errors
                if (uploadErrors.length > 0) {
                    // Create error summary
                    const errorSummary = uploadErrors.map(err =>
                        `${err.fileName}: ${err.error}`
                    ).join('\n');

                    notify(
                        `Upload Complete - ${counter - 1} / ${acceptedFiles.length} Files Uploaded`,
                        `Some files failed to upload:\n${errorSummary}`,
                        'warning'
                    );
                } else {
                    notify(
                        `${counter - 1} / ${acceptedFiles.length} File(s) Uploaded Successfully`,
                        'Your Files are now processing. Please allow a few minutes before the documents will be available to use.',
                        'success'
                    );
                }
            })
            .then(() => startCountdown())
            .catch(error => console.error('Error uploading files: ', error));

        Promise.all(uploadPromises)
            .catch(error => console.error('Error uploading files: ', error));
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        // Accept only '.jpeg', '.jpg', '.png','gif', 'pdf','bmp', 'tiff', 'heif', 'docx', 'xlsx', 'pptx', 'html'
        accept: {
            'application/pdf': ['.pdf'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/tiff': ['.tiff'],
            'image/heif': ['.heif'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/html': ['.html'],
        },
        maxSize: 30 * 1024 * 1024, // 30MB
        minSize: 1024, // 1kb min size - if 0kb it errors out.
        onDrop,
        onDropRejected: (fileRejections) => {
            // Initialize an array to hold messages for each rejected file
            const rejectionMessages = fileRejections.map(rejection => {
                // Get the file name
                const fileName = rejection.file.name;
                // Map each error to a readable message, we can customize these messages as needed
                const errorMessages = rejection.errors.map(error => {
                    switch (error.code) {
                        case 'file-too-large':
                            return `${fileName} is too large. Maximum size is 30MB.`;
                        case 'file-too-small':
                            return `${fileName} is reading as 0kb. It may be corrupt.`;
                        case 'file-invalid-type':
                            return `${fileName} is Not a supported file type.`;
                        // Add more cases as needed for different error codes
                        default:
                            return `File ${fileName} was rejected.`;
                    }
                }).join(' '); // Join messages for a single file if there are multiple errors

                return errorMessages;
            });

            // Set the detailed error messages as an array
            setDropErrorMessage(rejectionMessages);
        }
    });

    // function to clear the list of uploaded files
    useEffect(() => {
        if (indexName) {
            ClearUploadList();
        }
    }, [indexName]);

    const ClearUploadList = () => {
        //  setUploadFiles([]);
        setFiles([]);
        setUploadProgress(new Map());
        setUploadResponses(new Map());
        setHasUpload(false);
        setShowUploadList(false);
        setIsRunning(false);
    }




    return (
        <div className={styles.UploadContainer}>
            {uploadErrors.length > 0 ?

                <Dialog modalType='alert' >
                    <DialogTrigger disableButtonEnhancement>
                        <Button className={styles.errorMessage} appearance='transparent' icon={<DocumentError20Regular />}>
                            {uploadErrors.length} files failed to upload, view details.
                        </Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Upload Errors ({uploadErrors.length}):</DialogTitle>
                            <DialogContent>
                                <div className="error-list-container">
                                    {uploadErrors.map((error, index) => (
                                        <div
                                            key={`${error.fileName}-${index}`}
                                            className="error-item"
                                        >
                                            <div className="error-filename">{error.fileName}</div>
                                            <div className="error-message">{error.error}</div>
                                        </div>
                                    ))}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>


                : ''}

            {/* Rejection list */}
            {dropErrorMessage && dropErrorMessage.length > 0 && (
                <Dialog
                    open={isRejectedDialogOpen}
                    onOpenChange={toggleDialog}
                >
                    <DialogSurface>
                        <DialogTitle className={styles.errorHeader}>
                            <Text
                                size={500}
                                className={styles.errorHeader}
                            >
                                Attention!
                            </Text>
                        </DialogTitle>
                        <DialogContent>
                            <div className={styles.errorContainer}>
                                <List
                                    className={styles.error}
                                    items={dropErrorMessage}
                                    onRenderCell={(item, index) => (
                                        <div key={index} style={{ paddingLeft: '10px', textIndent: '-10px' }}>
                                            • {item}
                                        </div>
                                    )}
                                />
                                <br />
                                <Text as='p' align='center'>Please Note: Only the following types are currently supported: <br /> .jpeg, .jpg, .png, .gif, .pdf, .bmp, .tiff, .heif, .docx, .xlsx, .pptx, .html</Text>
                                <Divider />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance='outline'
                                onClick={toggleDialog}>Close</Button>
                        </DialogActions>
                    </DialogSurface>
                </Dialog>
            )}

            {/* Toaster */}
            <Toaster
                toasterId={toasterId} // toasterId allows multiple to be sent
                position='top' // position of the toaster on the screen
            />

            {/* conditionally render if indexName is filled */}
            {indexName ? (
                <>
                    {files.length == 0 ? (

                        <div className={styles.UploadArea}>
                            <h4>File Upload</h4>
                            <Field hint="When uploading files to the AI, please be aware that larger files and complex PDFs will take longer to process. Please remove any large CAD or Vector files from the PDF before uploading." size='medium'>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        flexBasis: '70%',
                                        border: isDragReject ? '2px dashed red' : isDragActive ? '2px dashed #0c7479' : '2px dashed #c8c8c8'
                                    }}
                                    className={styles.dropzone}
                                >
                                    <input {...getInputProps()} multiple />
                                    <CloudArrowUp24Regular /> Click to select files or drop them here (max file size 30MB)
                                    {/* {isDragActive && !isDragReject && <p>Drop the files in here ...</p>} */}
                                    {isDragReject && <p style={{ color: 'red' }}>Warning! Some files will be rejected...</p>}
                                </div>
                            </Field>
                            {/* Upload URL */}
                            <h4>Webpage Reference</h4>
                            <Field hint={<p>Blue Edge AI can reference content of specific web pages.
                                <br></br>Note: This will online include Text on this single page. No images, files or linked pages will be added.</p>}>
                                <UploadUrl index_name={indexName} setRefreshGrid={setRefreshGrid} />
                            </Field>
                        </div>
                    ) : (
                        < div className={styles.uploadDisplayContainer}>
                            {isRunning ? <Button size='small' icon={<Checkmark16Regular />} onClick={() => ClearUploadList()}>Clear ({doneCountdown})</Button> : ''}
                            {/* Map out each file */}
                            <div className={styles.uploadList}>
                                <h3 className={styles.subHeader}>{!uploadComplete ? "Uploading" : "Uploaded"} {uploadedDocsNumber} / {files.length}</h3>
                                <ol>
                                    {/* Map out files */}
                                    {files.map(file => (
                                        <li key={file.name}>
                                            <div className={styles.fileName} title={file.name}>{file.name}</div>
                                            {/* show progress bar */}
                                            {(uploadProgress.get(file.name) ?? 0) < 100 && (
                                                <ProgressBar
                                                    className={styles.progressBar}
                                                    value={uploadProgress.get(file.name) || 0}
                                                    max={100}
                                                />
                                            )}

                                            {uploadProgress.get(file.name) === 100 ? (
                                                <>
                                                    <CloudCheckmark24Regular className={styles.statusIcon} />
                                                </>
                                            ) : (
                                                <Spinner size='extra-small' as='div' className={styles.statusIcon} />
                                            )}
                                        </li>
                                    ))}
                                </ol>

                                {isRunning ? <Button size='small' icon={<Checkmark16Regular />} onClick={() => ClearUploadList()}>Clear ({doneCountdown})</Button> : ''}
                            </div>

                        </div>
                    )}
                </>) : (
                <p>Please select a collection to start</p>
            )
            }
        </div >
    );
};

export default UploadArea