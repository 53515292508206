// Nothing is passed in to this component. It relies on reading the projectID from session storage.
// Which is not working.

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Spinner } from "@fluentui/react-components";
import { ArrowReplyRegular } from "@fluentui/react-icons";
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomJwtPayload, getProjectRecordsApi, PersonaTemplate, Project } from "../../api";
import { Analytics } from "../../components/Analytics";
import { MaintenanceMode } from "../../components/MaintenanceMode";
import MainTool from "../../components/MainTool/MainTool";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import styles from "./Ai.module.css";

const Ai = () => {
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [isAuthorizedToViewQuickSettingsMenu, setIsAuthorizedToViewQuickSettingsMenu] = useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false);
    const navigate = useNavigate();
    const [NoRoleError, setNoRoleError] = useState<boolean>(false);
    // const [ tokensUsed, setTokensUsed ] = useState<number | null>(null);
    // const [ tokenLimitReached, setTokenLimitReached ] = useState<boolean>(false);
    const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
    const [refreshCollections, setRefreshCollections] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [mainTool, setMainTool] = useState<PersonaTemplate>();
    const [projects, setProjects] = useState<Project[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [storedProject, setStoredProject] = useState<string>(''); // project id stored in local storage
    const [isDemoModeActive, setIsDemoModeActive] = useState<boolean>(false); // demo mode status [true/false]

    useEffect(() => {
        if (isAuthenticated) {
            window.history.replaceState(null, '', '/#/ai');
        } else {
            console.log("Trigger redirect to login page at [ai.tsx]");
        }
    }, [isAuthenticated]);

    // 1. Check local storage for a project id
    useEffect(() => {
        const local = localStorage.getItem('project');
        // console.log("local ID found: ", local);
        setStoredProject(local ? local : '')
    }, []);


    // const fetchTokensUsed = async () => {
    //     if (user && user.name) {
    //         const accessToken = await getAccessTokenSilently();
    //         const tokens = await getTokensSinceResetApi(user.name, accessToken);
    //         setTokensUsed(tokens);
    //     }
    // };

    const fetchProjectDetails = async () => {
        // console.log("Starting fetchProjectDetails")
        const token = await getAccessTokenSilently();

        // Then fetch projects only if we have stored project
        if (storedProject) {
            // console.log("Stored project found, fetching project details")
            const allProjects = await getProjectRecordsApi(token);

            if (allProjects && Array.isArray(allProjects)) {
                setProjects(allProjects);
                const projectToSelect = allProjects.find(project => project.id === storedProject);
                if (projectToSelect) {
                    setSelectedProject(projectToSelect);
                }
            }
            setLoadingProjects(false);
        }
        else {
            console.log("Ending fetchProjectDetails without stored project")
        }
    }


    useEffect(() => {
        const initializeProjects = async () => {
            // Permissions
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;

                if (permissions.includes('view:Dashboard')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewQuickSettingsMenu(true);
                }
                if (!permissions.includes('read:Files')) {
                    setNoRoleError(true);
                    return;
                }
            } catch (error) {
                console.error('Error initializing:', error);
                navigate("/login");
            } finally {
                setIsLoading(false);
            }
        };
        initializeProjects();
        fetchProjectDetails();
    }, [storedProject]);


    // fetch tokens and project details
    useEffect(() => {
        // fetchTokensUsed();
        fetchProjectDetails();
    }, [user]);

    // useEffect(() => {
    //     const checkTokenLimit = async () => {
    //         if (user && user.name) {
    //             const token = await getAccessTokenSilently();
    //             const tokenLimit = await getTokenLimitApi(user.name, token);
    //             if (tokenLimit !== null && tokensUsed !== null && tokensUsed >= tokenLimit) {
    //                 setTokenLimitReached(true);
    //             } else {
    //                 setTokenLimitReached(false);
    //             }
    //         }
    //     };
    //     if (tokensUsed !== null) {
    //         checkTokenLimit();
    //     }
    // }, [ user, tokensUsed, getAccessTokenSilently ]);

    if (isLoading) {
        return (
            <div className={styles.containerAIPage}>
                <PageHeader authorizedDocumentCollectionsManager={isAuthorizedToViewDocumentCollectionsManager} authorizedBEAdmin={isAuthorizedToViewQuickSettingsMenu} collectionRefreshTrigger={setRefreshCollections} demoModeActive={isDemoModeActive} />
                <Analytics />
                <MaintenanceMode setDemoMode={setIsDemoModeActive} />
                <div className={styles.mainTool}>
                    <div className={styles.loadingScreen}>
                        <Spinner label={"LOADING TOOL"} />
                        <Button icon={<ArrowReplyRegular />} appearance="secondary" onClick={() => navigate('/hello')} style={{ marginBottom: '20px' }}>Cancel & Start Again</Button>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.containerAIPage}>
            <PageHeader authorizedDocumentCollectionsManager={isAuthorizedToViewDocumentCollectionsManager} authorizedBEAdmin={isAuthorizedToViewQuickSettingsMenu} collectionRefreshTrigger={setRefreshCollections} demoModeActive={isDemoModeActive} />
            <Analytics />
            <MaintenanceMode setDemoMode={setIsDemoModeActive} />
            {/* <div className={styles.newQueryButton} onClick={() => navigate('/hello')}>
                <AddCircle20Filled />
                <p>Start a new query</p>
            </div> */}
            {!loadingProjects ?
                <div className={styles.mainTool}>
                    {/* Tool */}
                    <Button icon={<ArrowReplyRegular />} appearance="secondary" onClick={() => navigate('/hello')} style={{ marginBottom: '20px' }}>Cancel & Start Again</Button>
                    <MainTool
                        selectedTool={mainTool}
                        projectInfo={selectedProject}
                        fileManagerAuthorized={isAuthorizedToViewDocumentCollectionsManager}
                        triggerCollectionRefresh={refreshCollections}
                        demoModeActive={isDemoModeActive}
                    />
                </div>
                :
                // Loading
                <div className={styles.mainTool}>
                    <div className={styles.loadingScreen}>
                        <Spinner label={"LOADING TOOL"} />
                        <Button icon={<ArrowReplyRegular />} appearance="secondary" onClick={() => navigate('/hello')} style={{ marginBottom: '20px' }}>Cancel & Start Again</Button>

                    </div>
                </div>
            }
        </div>
    );
};

export default Ai;