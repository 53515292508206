import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { ArchiveRegular, Cloud20Regular, DocumentPdfRegular, DocumentRegular, DocumentTextRegular, PreviewLinkRegular } from "@fluentui/react-icons";
import DOMPurify from "dompurify";
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import styles from "./Answer.module.css";

import { AskResponse, CollectionName } from "../../api";
import { getCollectionsApi, retrieveFileApi } from '../../api/api';
import { parseAnswerToHtml } from "./AnswerParser";

import { useAuth0 } from "@auth0/auth0-react";
import {
    Button
} from "@fluentui/react-components";
import { ArrowCircleDownRegular } from '@fluentui/react-icons';
import Feedback from "../Feedback/Feedback";



interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string, documentID: string, sourceName: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    selectedIndexes: CollectionName[];
    settingsButtonVisible?: boolean;
    webResultsIncluded: boolean;
    exportFollowupQuestions: (followupQuestions: string[]) => void;
    animate: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    // onThoughtProcessClicked,
    // onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    selectedIndexes,
    webResultsIncluded,
    exportFollowupQuestions,
    animate
}: Props) => {
    // removed the clickable link from the citation buttons
    // const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked, selectedIndexes[0]), [answer]);
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, selectedIndexes), [answer]);
    const [allCollectionNames, setAllCollectionNames] = useState<CollectionName[]>([]); // hold all names of collections

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [isCopied, setIsCopied] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [tokensUsed, setTokensUsed] = useState(Number);
    const [animatedAnswer, setAnimatedAnswer] = useState<string>('');
    const intervalRef = useRef<number | null>(null);

    // Function to get all collection names
    async function getAllCollectionNames() {
        const accessToken = await getAccessTokenSilently();
        const allCollectionNames = await getCollectionsApi(accessToken);
        setAllCollectionNames(allCollectionNames ?? []);
        return allCollectionNames ?? [];
    }

    // Use Effect to get all collection names on page load
    useEffect(() => {
        getAllCollectionNames();
    }, []);





    //create exported followup question array
    useEffect(() => {
        if (parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked) {
            exportFollowupQuestions(parsedAnswer.followupQuestions);
        }
    }, [parsedAnswer]);

    // handle click on citation
    async function handleCitationClick(
        index: string,
        sourceName: string,
        documentId: string
    ) {
        // check for values
        if (!index || !sourceName || !documentId) {
            console.log("Error: Citation values are missing when calling handleCitationClick in answer.tsx.");
            console.log("Index:", index);
            console.log("SourceName:", sourceName);
            console.log("DocumentId:", documentId);
            return { error: true, message: "An error occurred" };
        }
        const token = await getAccessTokenSilently();
        // Order: index_name, document_id, filename, accessToken
        try {
            const fileUrl = await retrieveFileApi(index, documentId, sourceName, token);
            onCitationClicked(fileUrl, documentId, sourceName);
        } catch (error) {
            console.error("Error retrieving file from API", error);
            alert("Error retrieving file from API");
        }
    };

    //extract the tokens use from 'thoughts' (This can probably be done differently but this is good for now.)
    useEffect(() => {
        if (answer?.thoughts) { // Ensure answer and thoughts exist
            const tokensMatch = answer.thoughts.match(/used (\d+) tokens/); // Match the number between "used" and "tokens"

            const tokens = tokensMatch ? parseInt(tokensMatch[1], 10) : 0; // Safely parse the matched number or default to 0

            setTokensUsed(tokens / 1000); // Set the divided value in state
        }
    }, [answer]);


    const wordsArrayRef = useRef<string[]>([]);
    const currentIndexRef = useRef<number>(0);
    const animationFrameRef = useRef<number | null>(null);
    const lastUpdateTimeRef = useRef<number>(0);
    const speedRef = useRef<number>(5); // ms between updates
    const wordsPerTick = 5;

    useEffect(() => {
        const splitHtmlIntoWords = (html: string) => {
            const regex = /(<[^>]+>)|([^<>\s]+\s*)/g;
            const matches = html.match(regex) || [];
            return matches.filter(item => item);
        };

        wordsArrayRef.current = splitHtmlIntoWords(sanitizedAnswerHtml);
        currentIndexRef.current = 0;
    }, [sanitizedAnswerHtml]);

    const animateWord = (timestamp: number) => {
        if (!lastUpdateTimeRef.current) {
            lastUpdateTimeRef.current = timestamp;
        }

        const elapsed = timestamp - lastUpdateTimeRef.current;

        if (elapsed >= speedRef.current) {
            const words = wordsArrayRef.current;
            const currentIndex = currentIndexRef.current;

            if (currentIndex >= words.length) {
                // Animation complete
                if (animationFrameRef.current) {
                    cancelAnimationFrame(animationFrameRef.current);
                    animationFrameRef.current = null;
                }
                return;
            }

            const endIndex = Math.min(currentIndex + wordsPerTick, words.length);
            const newContent = words.slice(0, endIndex).join('');
            currentIndexRef.current = endIndex;
            setAnimatedAnswer(newContent);

            lastUpdateTimeRef.current = timestamp;
        }

        // Request next frame
        animationFrameRef.current = requestAnimationFrame(animateWord);
    };

    const startAnswerAnimation = (customSpeed?: number) => {

        // Clear any existing animation
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
        }

        // Reset state
        currentIndexRef.current = 0;
        lastUpdateTimeRef.current = 0;
        speedRef.current = customSpeed ?? 50;
        setAnimatedAnswer('');

        // Start animation
        animationFrameRef.current = requestAnimationFrame(animateWord);
    };

    const resetAnimation = () => {
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
        }
        currentIndexRef.current = 0;
        lastUpdateTimeRef.current = 0;
        setAnimatedAnswer('');
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, []);

    // Auto-start animation when answer changes
    useEffect(() => {
        if (sanitizedAnswerHtml && animate) {
            startAnswerAnimation(20); // Slightly faster animation
        } else if (!animate) {
            setAnimatedAnswer(sanitizedAnswerHtml);
        }
    }, [sanitizedAnswerHtml, animate]);





    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">

            {/* Message bar success/fail copy */}
            {isCopied && (
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => setIsCopied(false)}
                    className={styles.messageBar}
                >
                    Text copied to clipboard!
                </MessageBar>
            )}

            <Stack.Item grow>
                {/* Alert that results contain content from the web */}
                {webResultsIncluded ?
                    <div className={styles.webResultWarning}>
                        <Cloud20Regular /> This answer includes sources from the internet, this may effect accuracy of results <Cloud20Regular />
                    </div> : null}
                <div className={styles.resultWarning}>
                    Please note that AI-generated responses might contain errors. Always double-check the information provided
                </div>
                {/* Markdown */}
                <div id="TheAnswer" className={styles.answerText}>
                    <ReactMarkdown children={animate ? animatedAnswer : sanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} />
                </div>

            </Stack.Item>


            {/* display citations if any */}
            {!!parsedAnswer.citations.length && (

                <div className={styles.citationList}>
                    <h3 className={styles.gizmoComponentSubheading}>PDF Citations</h3>
                    <Stack.Item>

                        <Stack wrap tokens={{ childrenGap: 5 }}>
                            {parsedAnswer.citations.map((x, i) => {
                                // Split the string into parts
                                const parts = x.split('/');

                                // Search allCollectionNames for the matching dp.index and set that as the display name
                                const collection = allCollectionNames.find(collection => collection.collection_name === parts[0]);
                                const displayName = collection ? collection.display_name : parts[0];

                                // format is: index/filename/documentId - extract values
                                const index = displayName;
                                const sourceName = parts[1]; // filename
                                const documentId = parts[2];


                                return (
                                    <div key={i}
                                        className={styles.citation} title={`${sourceName} - ${index}`}
                                        onClick={() => handleCitationClick(parts[0], sourceName, documentId)}

                                    >
                                        <div className={styles.citationNumber}>{i + 1}</div>
                                        <p>{sourceName}</p>
                                        <p className={styles.sourceCollection}><ArchiveRegular /> {index}</p>
                                    </div>

                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </div>
            )}
            {/* display sources if any [[MOVED UP TO PARENT]] */}
            {/* 
            {!!answer.data_points.length && (
                <>
                    <h3 className={styles.gizmoComponentSubheading}>All sources used</h3>
                    <p>Select a source to show more information</p>


                    <div className={styles.sources}>
                        <Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                {answer.data_points.map((dp, index) => {
                                    const fileExtensionIndex = dp.sourceName.lastIndexOf('.');
                                    let sourceName = dp.sourceName;
                                    let fileExtension;
                                    if (fileExtensionIndex != -1) {
                                        sourceName = dp.sourceName.substring(0, fileExtensionIndex);
                                        fileExtension = dp.sourceName.split('.').pop();
                                    }

                                    // Search allCollectionNames for the matching dp.index and set that as the display name
                                    const collection = allCollectionNames.find(collection => collection.collection_name === dp.index);
                                    const displayName = collection ? collection.display_name : dp.index;


                                    return (
                                        <div key={index} className={styles.sourcesItem} title={`${dp.sourceName} - ${index}`}
                                            onClick={() => handleCitationClick(dp.index, dp.sourceName, dp.documentId)}>
                                            <div className={styles.sourcesIcon}>
                                                {fileExtension == "pdf" ? <DocumentPdfRegular style={{ color: "#FF0000" }} className={styles.icon} /> : fileExtension == "docx" || fileExtension == "doc" ? <DocumentTextRegular style={{ color: "#295ba9" }} className={styles.icon} /> : fileExtension == "url" ? <PreviewLinkRegular className={styles.icon} /> : <DocumentRegular className={styles.icon} />}
                                            </div>

                                            <p className={styles.sourceName}>{sourceName}</p>
                                            <p className={styles.sourceCollection}>                                            <ArchiveRegular style={{ display: "inline-block" }} /> {displayName}</p>

                                        </div>
                                    )

                                })}
                            </Stack>
                        </Stack.Item>
                    </div>

                </>
            )}
             */}
            {/* Follow up questions [[ NOW MOVED UP TO PARENT ]]*/}
            {/* {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <>
                    <h3 className={styles.gizmoComponentSubheading}>Suggested Follow-up questions</h3>
                    <Stack.Item>
                        <Stack horizontal wrap className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            {parsedAnswer.followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </>
            )} */}


            {/* Feedback */}
            <Feedback
                run_id={answer.run_id}
            />


            {/* show amount of credits used */}

            {/* <Tooltip content={"Credits Used: " + tokensUsed} relationship="label">
                <Text className={styles.tokensUsed}><DataBarVerticalAscending16Filled /> {tokensUsed}</Text>
            </Tooltip> */}

        </Stack >
    );
};
