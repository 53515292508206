// VariableComponent.tsx
// This is the framework for the variable component that andy wants.
// We will pass in the description to be displayed as well as the user input.
// That input will be passed to the backend when the chat request is made.

import { Button, Textarea, TextareaOnChangeData, Tooltip } from '@fluentui/react-components';
import styles from './VariableComponentTwo.module.css'
import React, { useEffect, useState } from 'react'
import { DismissCircle24Filled, History24Regular } from '@fluentui/react-icons';

// States are in MainTool.tsx
type VariableComponentTwoProps = {
    setVariableComponentTwoUserInput: (userInput: string) => void;
    variableComponentTwoText: { title: string; text: string }; // Pulled from the Persona Template (cosmos)
    variableInputTwoText: string;
    disableVar: boolean;
    saveLastUsedTwo: string;

}

const VariableComponentTwo = ({
    setVariableComponentTwoUserInput,
    variableComponentTwoText,
    variableInputTwoText = '',
    disableVar,
    saveLastUsedTwo = ''
}: VariableComponentTwoProps) => {
    const [lastUsedTwo, setLastUsedTwo] = useState<string>('');
    const handleChange = (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        setVariableComponentTwoUserInput(data.value || '');
    };

    const handleClearTwo = () => {
        setVariableComponentTwoUserInput('');
    };

    const handleLastUsed = () => {
        setVariableComponentTwoUserInput(variableInputTwoText + ' ' + lastUsedTwo)
    }

    useEffect(() => {
        setLastUsedTwo(saveLastUsedTwo);
    }, [disableVar])


    return (
        <div className={styles.varContainer}>
            <div className={styles.varText}>
                {/* text */}
                {variableComponentTwoText.title && <h3 className={styles.varTitle}>{variableComponentTwoText.title}</h3>}
                {variableComponentTwoText.text && <p className={styles.varInstruction}>{variableComponentTwoText.text}</p>}
            </div>
            <div className={styles.varInput}>
                {/* User Input - Note: this is a string, so numbers will need to be converted */}
                <Textarea value={variableInputTwoText} appearance='outline' className={styles.varTextArea} resize='vertical' onChange={handleChange} disabled={disableVar} />
                <div className={styles.sideButtons}>
                    <Tooltip relationship='label' content="Clear" >
                        <Button
                            icon={<DismissCircle24Filled />}
                            onClick={handleClearTwo}
                            appearance='transparent'
                            disabled={disableVar}
                        />
                    </Tooltip>
                    {lastUsedTwo !== '' ?
                        <Tooltip relationship='label' content="Reuse last input" >
                            <Button
                                icon={<History24Regular />}
                                onClick={handleLastUsed}
                                appearance='transparent'
                                disabled={disableVar}
                            />
                        </Tooltip>
                        : ''}
                </div>

            </div>
        </div>
    )
}

export default VariableComponentTwo