// This component is a popover to display the upload status - which is the details of when it was processed and if it succeeded or failed. It does require to pass in the index name and the document id.

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Link, Spinner, Toast, Toaster, ToastTitle, ToastTrigger, Tooltip, useId, useToastController } from '@fluentui/react-components';
import { ArrowClockwiseDashesRegular, DocumentCheckmark20Regular, Info24Regular, SettingsCogMultiple20Filled, WarningFilled } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { retryProcessingApi, uploadStatusApi, UploadStatusApiResponse } from '../../api';
import styles from './UploadStatus.module.css';

type UploadStatusProps = {
    documentId: string;
    index: string;
    fileName: string;
    miniDisplay?: boolean;
    setHasFailed: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadStatus = ({
    documentId,
    index,
    fileName,
    miniDisplay,
    setHasFailed,
}: UploadStatusProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<UploadStatusApiResponse | null>(null);
    const [error, setError] = useState<string | null>(null); // If error when fetching file status
    const [warning, setWarning] = useState<boolean>(false);
    const [retrying, setRetrying] = useState<boolean>(false);

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const notifyError = () =>
        dispatchToast(
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >
                    <p>Failed trying to re-process the file. Please delete it and upload again. If you continue to have trouble please contact support.</p>
                </ToastTitle>
            </Toast>,
            { position: "top", intent: "error", timeout: 10000 }
        );
    const notifySuccess = () =>
        dispatchToast(
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >
                    <p>File re-processing was successfully started. Please wait a few minutes then try the reload button.</p>
                </ToastTitle>
            </Toast>,
            { position: "top", intent: "success", timeout: 10000 }
        )

    // Function  to fetch the upload status | Pass in documentId and index
    const fetchData = async () => {
        try {
            const token = await getAccessTokenSilently();
            const data = await uploadStatusApi(index, documentId, token);
            setUploadStatus(data as UploadStatusApiResponse);

            if (miniDisplay && data) {
                const lastUpdate = new Date(data.last_update);
                const now = new Date();
                const thresholdMinutes = 20 * 60 * 1000; // 20 minutes ago
                if (now.getTime() - lastUpdate.getTime() > thresholdMinutes && data.completed == false) {
                    setWarning(true);
                    setHasFailed(true);
                }

            }

        } catch (error) {
            console.error("Failed to fetch upload status:", error);
            setError(`Failed to fetch upload status. Please try again. Document ID: ${documentId}, Index: ${index}, File Name: ${fileName}, Error: ${error}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, [isOpen])

    // function to retry the upload - will need the documentId and index and filename
    const retryUpload = async () => {
        setRetrying(true);
        try {
            console.log("Starting Retry upload:", documentId, index, fileName);
            const token = await getAccessTokenSilently();
            const response = await retryProcessingApi(index, documentId, fileName, token);
            console.log("Retry upload response:", response);
            setRetrying(false);
            notifySuccess();
            fetchData();
        } catch (error) {
            console.error("Failed to retry upload:", error);
            setError(`Failed trying to re-process the file. Please delete and try again. Document ID: ${documentId}, Index: ${index}, File Name: ${fileName}, Error: ${error}`);
            notifyError();
            setRetrying(false);
        }
    };


    return (
        <>
            <Toaster toasterId={toasterId} />
            {!miniDisplay ? (
                <Dialog open={isOpen} onOpenChange={(e, { open }) => setIsOpen(open)}>
                    <DialogTrigger>
                        <Button appearance='transparent' icon={<Info24Regular />}>Process Status</Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogTitle>Upload Status</DialogTitle>
                        <DialogContent>
                            {error ? (
                                // Error message
                                <div style={{ padding: '10px' }}>
                                    <strong style={{ color: 'red' }}>Error:</strong>
                                    <p>Failed to fetch upload status. Please try again.</p>
                                    <ul>
                                        <li>Document ID: {documentId}</li>
                                        <li>Index: {index}</li>
                                        <li>File Name: {fileName}</li>
                                    </ul>
                                </div>
                            ) : uploadStatus ? (
                                <div style={{ padding: '10px', color: uploadStatus.failed ? 'red' : 'black' }}>
                                    <p>{fileName}</p>
                                    <div style={{ color: uploadStatus.completed ? '#01a4ad' : 'red' }}>Completed: {uploadStatus.completed.toString()}</div>
                                    <div>Creation: {uploadStatus.creation}</div>
                                    <div>Last Update: {uploadStatus.last_update}</div>
                                    <div>Steps Completed: {uploadStatus.completed_steps.join(', ')}</div>
                                    <div>Document ID: {uploadStatus.document_id}</div>
                                    <div style={{ color: !uploadStatus.empty ? '#01a4ad' : 'red' }}>Empty: {uploadStatus.empty.toString()}</div>
                                    <div>Collection: {uploadStatus.index}</div>
                                    <div>Remaining Steps: {uploadStatus.remaining_steps.join(', ')}</div>
                                    <div>All Steps: {uploadStatus.steps.join(', ')}</div>
                                    <div>Tags:</div>
                                    <ul>
                                        {Object.entries(uploadStatus.tags).map(([key, value]) => (
                                            <li key={key}>{key}: {JSON.stringify(value)}</li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div className={styles.loading}>Loading...</div>
                            )}
                        </DialogContent>
                        <DialogActions>
                            {/* Refresh button */}
                            <Button
                                appearance='primary'
                                onClick={fetchData}
                                title="Refresh"
                            >
                                Refresh
                            </Button>

                            {/* Retry Button */}
                            <Button
                                title='Close'
                                onClick={() => setIsOpen(false)}
                            >
                                Close
                            </Button>


                            {uploadStatus && !uploadStatus.completed && (
                                <Button
                                    appearance='outline'
                                    onClick={retryUpload}
                                    title="Retry"
                                >
                                    {retrying ?
                                        <Spinner
                                            label="Retrying..."
                                            size='extra-tiny'
                                        />
                                        :
                                        "Retry"
                                    }
                                </Button>
                            )}

                        </DialogActions>
                    </DialogSurface>
                </Dialog>
            ) : (
                <div className={styles.statusBtn}>
                    {uploadStatus ?
                        <>
                            {warning ?
                                <Tooltip relationship='description' appearance='inverted' content={'Processing Failed - please retry processing'}>
                                    <Button
                                        appearance='subtle'
                                        title=''
                                        icon={<WarningFilled color='red' />} />
                                </Tooltip>
                                : !uploadStatus.completed ?
                                    <Tooltip relationship='description' appearance='inverted' content={'Processing file'}>
                                        <Button
                                            appearance='subtle'
                                            icon={<SettingsCogMultiple20Filled
                                                color='orange' />}
                                        />
                                    </Tooltip>
                                    :
                                    <Tooltip relationship='description' appearance='inverted' content={'Ready to use'}>
                                        <Button
                                            appearance='subtle'
                                            icon={<DocumentCheckmark20Regular
                                                color='green'
                                            />}
                                        />
                                    </Tooltip>}

                        </> :
                        <Button onClick={fetchData} icon={<ArrowClockwiseDashesRegular />} appearance='subtle' />
                    }
                </div>
            )}
        </>
    );
};

export default UploadStatus;