// This component will export a single template and save a json locally.
// it will have the id of the template passed in.
// It will need to fetch the record from the db, then save it to a json file.



import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getTemplateByIdApi, PersonaTemplate } from '../../api';
import { Button, Field, Text } from '@fluentui/react-components';
import { saveAs } from 'file-saver';
import styles from './ExportTemplate.module.css';
import { ShareIos24Filled } from '@fluentui/react-icons';


type ExportTemplateProps = {
    id: string;
}

const ExportTemplate: React.FC<ExportTemplateProps> = ({ id }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [template, setTemplate] = useState<PersonaTemplate | null>(null); // state to hold the full template

    // Get the template from the db
    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const token = await getAccessTokenSilently();
                const template = await getTemplateByIdApi(id, token);
                setTemplate(template);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTemplate();
    }, [id, getAccessTokenSilently]);

    // Handle the export of the template
    const handleExport = () => {
        if (template) {
            const name = template.name;
            const blob = new Blob([JSON.stringify(template, null, 2)], { type: 'application/json' });
            // use file-saver to create and save the file
            saveAs(blob, `${name}.bet`);
        } else {
            console.error("No template data to export");
        }
    };

    return (
        <Button
            onClick={handleExport}
            appearance='secondary'
            icon={<ShareIos24Filled />}
        >Export
        </Button>
    );
}

export default ExportTemplate;