// Collections selector - list of collections the user can select from

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Checkbox, CheckboxOnChangeData, Divider, Popover, PopoverProps, PopoverSurface, Skeleton, SkeletonItem, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Add16Regular, Archive20Regular, ArchiveMultiple20Regular, ArchiveMultiple24Regular, ArchiveMultipleRegular, ArchiveRegular, ArrowLeft16Regular, ArrowRight16Regular, Dismiss16Regular, ErrorCircle24Regular, PulseRegular, Subtract16Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { lightTheme } from '../../BlueEdgeTheme';
import { CollectionName, getCollectionsApi } from '../../api';
import styles from './CollectionSelector.module.css';


interface Props {
  selectedCollections: CollectionName[];
  setSelectedCollections: (indexes: CollectionName[]) => void;
  refreshCollection: boolean;
  setRefreshCollection: (refreshCollection: boolean) => void;
  demoModeActive: boolean;
}

// Styles for the skeleton row
const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  skeletonRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    position: "relative",
    ...shorthands.gap("10px"),
    gridTemplateColumns: "min-content 80%",
    height: '5vh' // matches the height of the personaListItem
  },
  text: {
    color: lightTheme.colorBrandForeground1
  },
});


const CollectionSelector: React.FC<Props> = ({ selectedCollections, setSelectedCollections, refreshCollection, setRefreshCollection, demoModeActive }) => {
  const [collections, setCollections] = useState<CollectionName[] | undefined>(undefined);
  const uistyles = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [openErrorIndicator, setOpenErrorIndicator] = useState(false); //error popover
  const [groupedCollections, setGroupedCollections] = useState<{ [key: string]: CollectionName[] }>({});
  const [groupedCollectionsSelected, setGroupedCollectionsSelected] = useState<{ [key: string]: CollectionName[] }>({});
  const [detailsCollection, setDetailsCollection] = useState<CollectionName>();


  // handle open error popover
  const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
    setOpenErrorIndicator(data.open || false);

  // function to get the list of collections
  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      // localStorage.setItem('accessToken', token);
      const collectionsNameList = await getCollectionsApi(token);
      if (collectionsNameList) {
        setCollections(collectionsNameList);
      } else {
        setCollections(undefined);
      }
      setRefreshCollection(false);
    } catch (error) {
      console.error('Failed to retrieve collections', error);
      setCollections(undefined);
    }
  };

  // Fetch collections on component mount
  useEffect(() => {
    fetchData();
  }, []);

  //Refresh collection list
  useEffect(() => {
    if (refreshCollection) {
      fetchData();
    }
  }, [refreshCollection]);

  const handleSelectCollectionDetails = (collection: CollectionName) => {
    if (detailsCollection == collection) {
      setDetailsCollection(undefined)
    } else {
      setDetailsCollection(collection);
    }
  }


  // handle collection selection
  const onClickChange = (collection: CollectionName) => {
    let newSelectedCollections = [...selectedCollections]; // creates a new array with same data
    const isSelected = newSelectedCollections.some(newSelectedCollections => newSelectedCollections.id === collection.id)
    if (!isSelected) {
      newSelectedCollections.push(collection);
    } else {
      newSelectedCollections = newSelectedCollections.filter((selectedCollection) => selectedCollection.id !== collection.id);
    }
    setSelectedCollections(newSelectedCollections);
    localStorage.setItem('selectedCollections', JSON.stringify(newSelectedCollections));
  }


  // Sort collections by category
  useEffect(() => {
    if (collections) {
      const grouped = collections.reduce((acc, collection) => {
        if (!acc[collection.category]) {
          acc[collection.category] = [];
        }
        acc[collection.category].push(collection);
        return acc;
      }, {} as { [key: string]: CollectionName[] });
      setGroupedCollections(grouped);
    }
  }, [collections, selectedCollections]);

  // Sort Selected collections by category
  useEffect(() => {
    if (selectedCollections) {
      const grouped = selectedCollections.reduce((acc, collection) => {
        if (!acc[collection.category]) {
          acc[collection.category] = [];
        }
        acc[collection.category].push(collection);
        return acc;
      }, {} as { [key: string]: CollectionName[] });
      setGroupedCollectionsSelected(grouped);
    }
  }, [selectedCollections, collections]);




  return (
    <>
      <div className={styles.selectorContainer}>
        <div className={styles.selectorToolbar}>
          <p><Archive20Regular /> Your Collections </p>
          <p><ArchiveMultiple20Regular /> Selected Collections</p>
        </div>
        <div className={styles.selectorMainContent}>
          {/* Left side shows all collections unselected */}
          <div className={styles.selectorLeft}
            data-tg-tour="Here you will see a list of all the collections available to you..."
            data-tg-title="Your collections"
            data-tg-group="tourDocumentCollections"
            data-tg-order="1">
            {/* List the collections */}
            {collections === null ? (
              <div style={{ textAlign: 'center' }}>
                <p>There was an error fetching the collections.</p>
              </div>
            ) : collections === undefined ? (
              // display 4 skeleton checkboxes if no collections are loaded yet
              <div style={{ textAlign: 'center' }}>
                <Skeleton >
                  <div className={uistyles.skeletonRow}>
                    <SkeletonItem shape="square" size={24} />
                    <SkeletonItem />
                  </div>
                </Skeleton>
                <Skeleton >
                  <div className={uistyles.skeletonRow}>
                    <SkeletonItem shape="square" size={24} />
                    <SkeletonItem />
                  </div>
                </Skeleton>
                <Skeleton >
                  <div className={uistyles.skeletonRow}>
                    <SkeletonItem shape="square" size={24} />
                    <SkeletonItem />
                  </div>
                </Skeleton>
                <Text
                  as='p'
                  size={200}
                  className={uistyles.text}
                >Loading Collections...</Text>
              </div>
            ) : collections.length === 0 ? (
              // display a message if collections is an empty array
              <div style={{ textAlign: 'center' }}>
                <p>No collections found.</p>
              </div>
            ) : (
              // display the collections with category header
              <div className={styles.list}
                data-tg-tour="Use the + to add Document Collections to your Selected Collections. These will be used as a source in your query...."
                data-tg-title="Add collections"
                data-tg-group="tourDocumentCollections"
                data-tg-order="2">
                {Object.keys(groupedCollections)
                  .sort((a, b) => a.localeCompare(b))
                  .map((category, index) => (
                    <div key={index}>
                      <h3 className={styles.gizmoComponentSubheading}>{category}</h3>
                      <div>
                        {groupedCollections[category]
                          .filter((collection) => {
                            if (demoModeActive) {
                              // When demoModeActive is true, only include items that start with "Contoso"
                              return collection.display_name.startsWith("Contoso");
                            } else {
                              // When demoModeActive is false, exclude items that start with "Contoso"
                              return !collection.display_name.startsWith("Contoso");
                            }
                          })
                          .sort((a, b) => a.display_name.localeCompare(b.display_name))
                          .map((collection, index) => {
                            const isSelected = selectedCollections.some(
                              (selectedCollection) => selectedCollection.id === collection.id
                            );
                            const collectionClass = isSelected ? styles.selectedCollection : '';
                            const highlightClass = collection.id === detailsCollection?.id ? styles.highlightCollection : '';

                            return (
                              <div
                                key={index}
                                className={`${styles.collectionSelectBox} ${collectionClass} ${highlightClass}`}
                                onClick={() => onClickChange(collection)}
                              >
                                <p className={styles.collectionName}>
                                  <ArchiveRegular /> {collection.display_name}
                                </p>
                                <Button
                                  size="small"
                                  icon={isSelected ? <Dismiss16Regular /> : <Add16Regular />}
                                  appearance={isSelected ? "secondary" : "primary"}
                                  onClick={() => onClickChange(collection)}
                                />
                              </div>
                            );
                          })}

                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Right hand is selected collections */}
          <div className={styles.selectorRight}
            data-tg-tour="Here you will see a list of all the collections you have selected to use. Click the X to remove a Document Collection from the list..."
            data-tg-title="Selected collections"
            data-tg-group="tourDocumentCollections"
            data-tg-order="3">
            <div className={styles.list}>
              {Object.keys(groupedCollectionsSelected)
                .sort((a, b) => a.localeCompare(b))
                .map((category, index) => (
                  <div key={index}>
                    <h3 className={styles.gizmoComponentSubheading}>{category}</h3>
                    <div>
                      {groupedCollectionsSelected[category]
                        .sort((a, b) => a.display_name.localeCompare(b.display_name))
                        .map((collection, index) => {
                          const isSelected = selectedCollections.some(
                            (selectedCollection) => selectedCollection.id === collection.id
                          );

                          return (
                            <div
                              key={index}
                              className={styles.collectionSelectBox}
                              onClick={() => onClickChange(collection)}
                            >

                              <p className={styles.collectionName}>
                                <ArchiveRegular /> {collection.display_name}
                              </p>
                              <Button
                                disabled={!isSelected}
                                size="small"
                                icon={<Dismiss16Regular />}
                                appearance="secondary"
                                onClick={() => onClickChange(collection)}

                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
            </div>
            <Button appearance="subtle" disabled={selectedCollections.length == 0} onClick={() => setSelectedCollections([])}>Remove all</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectionSelector