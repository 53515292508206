// This component will display the terms of service for the app.
// The TOS is 30 pages, so with performance in mind we will load the text from a file.

// tosmd.md is in /public

import { useEffect, useState } from 'react';
import styles from './TermsOfService.module.css';
import { getTermsAndConditionsApi, setTosAcceptanceApi } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Text } from '@fluentui/react-components';
import { version } from 'os';


const TermsOfService = () => {
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [tandcVersion, setTandcVersion] = useState(''); //this is the version number for T&C
    const [tandcText, setTandcText] = useState(''); //this is the text for the T&C
    const [tosAccepted, setTosAccepted] = useState<boolean>(true);
    const [tosAcceptedCurrentVersion, setTosAcceptedCurrentVersion] = useState<boolean>(true);

    // useEffect to check for tos cookie
    useEffect(() => {
        const cookie = document.cookie;
        const tosAcceptedCookie = cookie.match("tos_accepted=true");
        const versionMatch = "tos_version=" + tandcVersion;
        const tosAcceptedCurrentVersionCookie = cookie.match(versionMatch);
        if (!tosAcceptedCookie || !tosAcceptedCurrentVersionCookie) {
            setTosAccepted(false);
            setTosAcceptedCurrentVersion(false)
        }
    }, [tandcVersion])

    // When closing the TOS set a cookie that expires in 100 years
    const handleClose = async () => {
        setTosAccepted(true);
        const date = new Date();
        date.setFullYear(date.getFullYear() + 100); // 100 years in the future
        const expires = "; expires=" + date.toUTCString();
        document.cookie = "tos_accepted=true; path=/; " + expires;
        document.cookie = "tos_version=" + tandcVersion + "; path=/; " + expires;

        setTosAcceptanceApi(user?.name || "Unknown", tandcVersion)
    }

    // Function to Fetch the T&C settings from the server
    const fetchTandCSettings = async () => {
        try {
            const token = await getAccessTokenSilently();
            const data = await getTermsAndConditionsApi(token);
            if (data) {
                setTandcVersion(data.version);
                setTandcText(data.text);
            }
        } catch (error) {
            console.error("T&C FETCH ERROR: " + error);
        }
    };

    useEffect(() => {
        fetchTandCSettings();
    }, []);

    return (
        <>            {!tosAccepted && (
            <div className={styles.container}>
                <Text
                    as="h1"
                    style={{ color: "var(--primary-color)", fontSize: "2em", paddingBottom: "1em" }}
                >Welcome to Blue-Edge AI.</Text>

                <div dangerouslySetInnerHTML={{ __html: tandcText }} className={styles.tosText} />
                <Button onClick={handleClose}
                    className={styles.modalButton}
                    appearance='primary'
                >Agree to MSA and continue</Button>
            </div>
        )}
        </>
    )
}

export default TermsOfService