import React, { useEffect, useState } from 'react';
import { Spinner, Button, Text } from '@fluentui/react-components';
import { deleteProjectRecordApi, PersonaTemplate, Project } from '../../api'; // Type
import styles from './MyLibrary.module.css';
import { PersonaIcon } from '../PersonaIcon';
import { useNavigate } from "react-router-dom";
import { DeleteRegular, ChevronDownRegular, ArrowRightFilled } from '@fluentui/react-icons';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  projectList: Project[];
  personasList: PersonaTemplate[];
  setLoadingProjects: React.Dispatch<React.SetStateAction<boolean>>;
  handleRecentToolSelect: React.Dispatch<PersonaTemplate>;
  loadingProjects: boolean;
}

export const MyLibrary: React.FC<Props> = ({ projectList, personasList, setLoadingProjects, loadingProjects, handleRecentToolSelect }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [showAll, setShowAll] = useState(false);
  const [lastUsedTool, setLastUsedTool] = useState<PersonaTemplate>();
  const [isLoadingTool, setIsLoadingTool] = useState<boolean>(true);

  const handleSelectProject = async (project: Project) => {
    localStorage.setItem('project', project.id)
    navigate("/ai")
  }

  const deleteProject = async (project_id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await deleteProjectRecordApi(project_id, accessToken);
      console.log("Empty Project Deleted");
    } catch (error) {
      console.error("Error deleting project:", error);
    } finally {
      setLoadingProjects(true);
    }
  };

  const sortedProjects = projectList.sort((a, b) => {
    const parseDate = (dateString: string) => {
      const [datePart, timePart] = dateString.split(' - ');
      const [day, month, year] = datePart.split('/').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
      return new Date(year, month - 1, day, hours, minutes);
    };

    const dateA = parseDate(a.last_updated);
    const dateB = parseDate(b.last_updated);

    return dateB.getTime() - dateA.getTime();
  });

  //delete empty projects and grab last used tool
  useEffect(() => {
    const cleanupEmptyProjects = async () => {
      try {
        // Find empty projects that match the criteria
        const emptyProjects = projectList.filter(project =>
          project.project_name === "New Session" &&
          project.project_collections === "new" &&
          project.prompts === "new"
        );

        // Delete each empty project
        for (const project of emptyProjects) {
          await deleteProject(project.id);
        }
      } catch (error) {
        console.error("Failed to clean up empty projects: " + error)
      } finally {
        // Find last used tool
        const lastUsed = personasList.find(
          (personaTool) => personaTool.name === sortedProjects[0].project_tool_name

        );
        setLastUsedTool(lastUsed);
      }


    };



    // Run cleanup
    cleanupEmptyProjects();
  }, [projectList]);



  const displayedProjects = showAll ? sortedProjects.slice(0, 5) : sortedProjects.slice(0, 3);

  const renderProject = (project: Project, index: number) => {
    const persona = personasList.find(persona => persona.name === project.project_tool_name);
    const iconName = persona ? persona.iconName : '';
    return (
      <div className={styles.projectContainer} key={index} onClick={() => handleSelectProject(project)}>

        <div className={styles.projectDetails}>
          <div className={styles.projectIcon}>
            <PersonaIcon iconName={iconName} />
          </div>
          <p className={styles.projectName}>{project.project_name}</p>
        </div>
        <Button
          className={styles.projectDelete}
          appearance='secondary'
          size='small'
          icon={<DeleteRegular />}
          onClick={(event) => {
            event.stopPropagation();
            deleteProject(project.id);
          }}
        />
      </div>
    );
  };

  return (
    <div className="ms-Grid">
      {loadingProjects ? (
        <Spinner />
      ) : (

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg6">
            {lastUsedTool ?
              <>
                <h4 className={styles.librarySubHeading}>Last tool:</h4>
                <div className={styles.recentTool}>
                  <div
                    className={styles.toolMenuItem}
                    onClick={() => handleRecentToolSelect(lastUsedTool)}
                    style={{ padding: '10px', borderBottom: '1px solid #ccc' }}
                  >
                    <div className={styles.toolIcon}>
                      <PersonaIcon
                        iconName={lastUsedTool.iconName}
                        iconColor={lastUsedTool.color} // use category color instead of the tool color
                      />
                    </div>
                    <div className={styles.toolItemDetails}>
                      <Text className={styles.toolName}>{lastUsedTool.name} <ArrowRightFilled /></Text>
                      <div dangerouslySetInnerHTML={{ __html: lastUsedTool.long_description ?? '' }} />
                    </div>
                  </div>
                </div>
              </>
              : ''}
          </div>
          <div className="ms-Grid-col ms-lg6">
            <h4 className={styles.librarySubHeading}>Recent sessions:</h4>

            <div className={styles.recentSessions}>

              {projectList.length === 0 ? (
                <p>You last few sessions will appear here to continue later.</p>
              ) : (
                <>
                  {displayedProjects.map(renderProject)}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}