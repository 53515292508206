// this component will take a url and submit it to the backend

import { useAuth0 } from '@auth0/auth0-react';
import { TextField } from '@fluentui/react';
import { Button, Field, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { Globe24Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { uploadFileApi, uploadUrlApi } from '../../api';
import styles from './UploadUrl.module.css';

type UploadUrlProps = {
    index_name: string;
    setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>; // true = trigger refresh in file status list

}

const UploadUrl = ({ index_name, setRefreshGrid }: UploadUrlProps) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [url, setUrl] = useState<string>(''); // url to upload
    const { getAccessTokenSilently, user } = useAuth0();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');



    const clearInput = () => {
        setUrl(''); // Clear the URL input field
        setSuccessMessage(''); // Clear any previous success message
        setErrorMessage(''); // Clear any previous error message
    }


    // handle upload
    const handleUpload = async () => {
        setIsProcessing(true);
        try {
            const userTag = user?.name?.toString() || 'unknown'; // create a userTag with the user.name from auth0
            const token = await getAccessTokenSilently();

            // Create a FormData object
            const formData = new FormData();
            // Create a blob from the URL string
            const contentBlob = new Blob([url], { type: 'text/plain' });
            // Dynamically create filename based off url
            const urlToUseAsFilename = url
                .replace(/^https?:\/\//, ''); // Remove http:// or https://

            // Set as name for file
            const filename = `webpage-${urlToUseAsFilename}.url`;

            // Append the blob to the FormData object, using a filename
            formData.append('files', contentBlob, filename);
            // Optionally, append other data like userTag if needed
            formData.append('userTag', userTag);

            // Extract the URL from the FormData object
            const urlToUpload = url;

            // Call the uploadUrlApi function
            const response = await uploadUrlApi(urlToUpload, index_name, [userTag], token);

            // Parse the response
            const data = response; // Assuming response is already JSON
            setSuccessMessage(`Webpage successfully added to Document Collection.`);
            setErrorMessage(''); // Clear any previous error message
            setUrl('');


        } catch (error) {
            console.error(error);
            setIsProcessing(false);
            if (error instanceof Error) {
                setErrorMessage(`Error: ${error.message}`);
            } else {
                // Handle cases where the error is not an Error instance
                setErrorMessage(`An unknown error occurred`);
            }
            setSuccessMessage(''); // Clear any previous success message
        } finally {
            setIsProcessing(false);
            setRefreshGrid(true);
        }
    }
    // function to clear on collection name
    useEffect(() => {
        if (index_name) {
            setUrl(''); // Clear the URL input field
            setSuccessMessage(''); // Clear any previous success message
            setErrorMessage(''); // Clear any previous error message
        }
    }, [index_name]);


    const validateURL = (url: string) => {
        if (!url) {
            return { isValid: false, error: 'URL is required' };
        }

        if (url.length > 235) {
            return { isValid: false, error: 'URL must not exceed 235 characters' };
        }

        if (/\s/.test(url)) {
            return { isValid: false, error: 'URL must not contain spaces' };
        }

        if (!/^(https?:\/\/)/.test(url)) {
            return { isValid: false, error: 'URL must start with http:// or https://' };
        }

        if (!/^(https?:\/\/)([a-z\d]([a-z\d-]*[a-z\d])*\.)+[a-z]{2,}(:\d{2,5})?(\/.*)?$/i.test(url)) {
            return { isValid: false, error: 'URL format is invalid' };
        }

        return { isValid: true, error: null };
    };



    return (
        <div>
            <div className={styles.inputContainer}>
                <Popover withArrow onOpenChange={clearInput}>
                    <PopoverTrigger disableButtonEnhancement>
                        <Button icon={<Globe24Regular />}>Add Website Page</Button>
                    </PopoverTrigger>

                    <PopoverSurface tabIndex={-1}>
                        <div className={styles.textField}>
                            <Field
                                label="Insert the web page address:"
                                validationState={url ? (validateURL(url).isValid ? "success" : "error") : undefined}
                                validationMessage={url ? (validateURL(url).isValid ? "Valid URL" : validateURL(url).error) : undefined}
                                hint={'Example: https://www.networkrail.co.uk/running-the-railway/'}
                            >
                                <TextField
                                    value={url}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        if (newValue != null) {
                                            setUrl(newValue);
                                        }
                                    }}
                                />
                            </Field>
                        </div>

                        <div className={styles.button}>
                            <Button
                                appearance='primary'
                                onClick={handleUpload}
                                disabled={isProcessing || !validateURL(url).isValid}
                            >
                                {isProcessing ? "Validating..." : "Add Page"}
                            </Button>
                            {/* Error / Success message */}
                            <>
                                {successMessage && (
                                    <div style={{ color: 'green', marginBottom: '10px' }}>
                                        {successMessage}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div style={{ color: 'red', marginBottom: '10px' }}>
                                        {errorMessage}
                                    </div>
                                )}
                            </>
                        </div>
                    </PopoverSurface>
                </Popover>



            </div>


        </div>
    )
}

export default UploadUrl