// This is the expandable tool selector. We pass in the list of tools and the list of category.
import { useAuth0 } from '@auth0/auth0-react';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from '@fluentui/react-accordion';
import { Text } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import React from 'react';
import { getAllTemplateCategoriesApi, PersonaTemplate, TemplateCategory } from '../../api';
import { PersonaIcon } from '../PersonaIcon';
import styles from './ToolSelector.module.css';
import { ArrowRightFilled, ChevronCircleDownFilled, ChevronCircleRightFilled, ChevronRightFilled } from '@fluentui/react-icons';


type ToolSelectorProps = {
    personasList: PersonaTemplate[];
    handleToolSelect: (tool: PersonaTemplate) => void;
}

const ToolSelector = ({ personasList, handleToolSelect }: ToolSelectorProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [categoryNames, setCategoryNames] = useState<TemplateCategory[]>([]);
    const [openItem, setOpenItems] = useState('');
    const [defaultOpen, setDefaultOpen] = useState('');

    //handle the switching of accordion sections
    const handleToggle = React.useCallback<AccordionToggleEventHandler>(
        (_, data) => {
            setOpenItems(data.value as string);
        },
        []
    );


    // Function to get all template_categories to fill in the headers for the selector
    const getTemplateCategories = async () => {
        // call the api function that returns all template categories
        const accessToken = await getAccessTokenSilently();
        const templateCategories = await getAllTemplateCategoriesApi(accessToken);
        try {
            // set that result to state
            setCategoryNames(templateCategories);
        } finally {
            setDefaultOpen(templateCategories[0].id);
        }
    }

    // useEffect to call getTemplateCategories on load
    useEffect(() => {
        getTemplateCategories();
    }, []);


    return (
        <div>
            <Accordion
                defaultOpenItems={defaultOpen}// id for: Research Your Document Collections
                multiple={false}
                collapsible={true}
                onToggle={handleToggle}
            >
                {categoryNames
                    .filter(category => category.category_name !== "NOT USED")

                    .sort((a, b) => a.category_name.localeCompare(b.category_name)) // sort categories alphabetically
                    .map((category) => (
                        <AccordionItem key={category.category_name} value={category.id}>
                            {/* Category Header */}
                            <AccordionHeader
                                expandIcon={openItem === category.id ? <ChevronCircleDownFilled color={category.color} /> : <ChevronCircleRightFilled color={category.color} />}
                                className={openItem === category.id ? styles.accordionHeaderSelected : styles.accordionHeader}
                                style={openItem === category.id ? { backgroundColor: category.color } : {}}
                            >
                                <div>
                                    <Text size={400} style={{ color: category.color }}>{category.category_name}</Text>
                                    <br />
                                    <Text size={300} className={styles.accordionDescription}>{category.description}</Text>
                                </div>
                            </AccordionHeader>
                            {/* The tools for that category */}
                            <AccordionPanel className={styles.accordionPanel} style={{ borderColor: category.color }}>
                                <div className={styles.toolGrid}>
                                    {personasList
                                        .filter(tool => tool.template_category === category.category_name) // filter tools by category
                                        .sort((a, b) => a.name.localeCompare(b.name)) // sort tools alphabetically by name
                                        .map((tool, index) => (
                                            // map through the tools and display them
                                            <div
                                                key={index}
                                                className={styles.toolMenuItem}
                                                onClick={() => handleToolSelect(tool)}
                                                style={{ padding: '10px', borderBottom: '1px solid #ccc' }}
                                            >
                                                <div className={styles.toolIcon}>
                                                    <PersonaIcon
                                                        iconName={tool.iconName}
                                                        iconColor={category.color} // use category color instead of the tool color
                                                    />
                                                </div>
                                                <div className={styles.toolItemDetails}>
                                                    <Text className={styles.toolName}>{tool.name} <ArrowRightFilled /></Text>
                                                    <div dangerouslySetInnerHTML={{ __html: tool.long_description ?? '' }} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
            </Accordion>
        </div>
    )
}

export default ToolSelector