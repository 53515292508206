/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAuth0 } from "@auth0/auth0-react";
import {
    Toolbar,
    ToolbarButton
} from "@fluentui/react-components";
import {
    ArchiveSettings20Regular,
    BuildingHome20Regular,
    Chat24Regular,
    Home20Filled,
    Person20Filled,
    Settings20Regular,
    SlideTextPerson20Regular,
    Toolbox20Filled,
} from "@fluentui/react-icons";
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from "react";
import { CustomJwtPayload } from "../../api";
import styles from "./ManagementToolbar.module.css";


export const ManagementToolbar = () => {

    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false);
    const [isAuthorizedToViewCompanyProfile, setIsAuthorizedToViewCompanyProfile] = useState<boolean>(false);
    const [isAuthorizedToViewUsers, setIsAuthorizedToViewUsers] = useState<boolean>(false);
    const { getAccessTokenSilently } = useAuth0();
    const [isAuthorizedToViewQuickSettingsMenu, setIsAuthorizedToViewQuickSettingsMenu] = useState(false);


    // Get permissions
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                // Quick Settings Menu
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewQuickSettingsMenu(true);
                }
                // Document Collections Manager
                if (permissions.includes('view:Dashboard')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                // view users
                if (permissions.includes('view:Users')) {
                    setIsAuthorizedToViewUsers(true);
                };
                // Company Profile
                if (permissions.includes('view:CompanyProfile')) {
                    setIsAuthorizedToViewCompanyProfile(true);
                };
            } catch (e) {
                // Handle errors - e.g. token is invalid, expired, etc.
                console.error(e);
                alert("There was an issue verifying your credentials. Please log in again.")
            }
        }
        checkRole();
    }, [])

    return (
        <div className={styles.managementToolbarContainer}>
            < Toolbar >
                <a href="#/hello">
                    <ToolbarButton
                        aria-label="Hello"
                        icon={<Home20Filled />}
                        title="Hello"
                    />
                </a>
                {/* Hide due to file upload being removed. */}
                {/* {isAuthorizedToViewDocumentCollectionsManager ? (
                    <a href="#/collections">
                        <ToolbarButton
                            aria-label="Collection Manager"
                            icon={<ArchiveSettings20Regular />}
                            title="Collection Manager"
                        />
                    </a>
                ) : ''} */}
                {isAuthorizedToViewQuickSettingsMenu ? (
                    <a href="#/personas">
                        <ToolbarButton
                            aria-label="Tool Manager"
                            icon={<Toolbox20Filled />}
                            title="Tool Manager"
                        />
                    </a>
                ) : ''}
                {isAuthorizedToViewUsers ? (
                    <a href="#/users">
                        <ToolbarButton
                            aria-label="User Manager"
                            icon={<SlideTextPerson20Regular />}
                            title="User Manager"
                        />
                    </a>
                ) : ''}
                {isAuthorizedToViewCompanyProfile ? (
                    <a href="#/profile">
                        <ToolbarButton
                            aria-label="Company Profile"
                            icon={<BuildingHome20Regular />}
                            title="Company Profile"
                        />
                    </a>
                ) : ''}
                {isAuthorizedToViewCompanyProfile ? (
                    <a href="#/admin">
                        <ToolbarButton
                            aria-label="Admin Page"
                            icon={<Settings20Regular />}
                            title="Admin Page"
                        />
                    </a>
                ) : ''}

            </Toolbar >
        </div>
    );
};

export default ManagementToolbar;

