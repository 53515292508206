import React, { useEffect, useState } from 'react'
import styles from './VariableComponent.module.css'
import { Button, Textarea, TextareaOnChangeData, Tooltip } from '@fluentui/react-components';
import { DismissCircle24Filled, History24Regular } from '@fluentui/react-icons';

type VariableComponentProps = {
    setVariableComponentUserInput: (userInput: string) => void;
    variableComponentText: { title: string; text: string };
    variableInputText: string;
    disableVar: boolean;
    saveLastUsed: string;
}

const VariableComponent = ({
    setVariableComponentUserInput,
    variableComponentText,
    variableInputText = '',
    disableVar,
    saveLastUsed = ''
}: VariableComponentProps) => {

    const [lastUsed, setLastUsed] = useState<string>('');
    const handleChange = (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        setVariableComponentUserInput(data.value || '');
    };

    const handleClear = () => {
        setVariableComponentUserInput('');
    };

    const handleLastUsed = () => {
        setVariableComponentUserInput(variableInputText + ' ' + lastUsed)
    }

    useEffect(() => {
        setLastUsed(saveLastUsed);
    }, [disableVar])

    return (
        <div className={styles.varContainer}>
            <div className={styles.varText}>
                {variableComponentText.title && <h3 className={styles.varTitle}>{variableComponentText.title}</h3>}
                {variableComponentText.text && <p className={styles.varInstruction}>{variableComponentText.text}</p>}
            </div>
            <div className={styles.varInput}>
                <Textarea
                    value={variableInputText || ''}
                    color='gray'
                    appearance='outline'
                    className={styles.varTextArea}
                    resize='vertical'
                    onChange={handleChange}
                    disabled={disableVar}
                />
                <div className={styles.sideButtons}>
                    <Tooltip relationship='label' content="Clear" >
                        <Button
                            icon={<DismissCircle24Filled />}
                            onClick={handleClear}
                            appearance='transparent'
                            disabled={disableVar}
                        />
                    </Tooltip>
                    {lastUsed !== '' ?
                        <Tooltip relationship='label' content="Reuse last input" >
                            <Button
                                icon={<History24Regular />}
                                onClick={handleLastUsed}
                                appearance='transparent'
                                disabled={disableVar}
                            />
                        </Tooltip>
                        : ''}
                </div>
            </div>
        </div>
    )
}

export default VariableComponent