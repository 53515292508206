// This will be the user management page in the dashboard. It will display a list of users and their information.
// Future plans: 
// 1 - ablity to reset tokens for the period
// 2 - ability to add/delete basic users 

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Image, Text, Tooltip, TooltipProps } from '@fluentui/react-components';
import { Chat24Regular, Home24Filled, Info24Regular } from '@fluentui/react-icons';
import { jwtDecode } from 'jwt-decode';
import { CustomJwtPayload, getUsersInfoApi, resetTokensApi, setTokenLimitApi } from '../../api';
import ClientLogo from "../../assets/ClientLogo.png";
import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './Users.module.css';
import { ManagementToolbar } from '../../components/ManagmentToolbar';
interface User {
    user_id: string;
    token_limit: number;
    total_tokens: number;
    tokens_this_period: number;
}

const toolTipText = <p>Azure OpenAI processes text by breaking it down into tokens. Tokens can be words or just chunks of characters. For example, the word “hamburger” gets broken up into the tokens “ham”, “bur” and “ger”, while a short and common word like “pear” is a single token. Many tokens start with a whitespace, for example “ hello” and “ bye”.
    <br /><br />
    The total number of tokens processed in a given request depends on the length of your input, output and request parameters. The quantity of tokens being processed will also affect your response latency and throughput for the models.</p>


const Users = (props: Partial<TooltipProps>) => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [users, setUsers] = React.useState<User[]>([]);
    const [isAuthorizedToView, setIsAuthorizedToView] = React.useState(false);

    // Check auth
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
            console.log("Login triggerd at [Users.tsx]")
        }
    }, [isAuthenticated, navigate]);

    // get fresh access token and check roles
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                if (permissions.includes('view:Dashboard-Users')) {
                    setIsAuthorizedToView(true);
                }
                else {
                    setIsAuthorizedToView(false);
                    alert("You are not authorized to view this page");
                    navigate("/");
                }
            } catch (e) {
                // Handle errors - e.g. token is invalid, expired, etc.
                console.error(e);
                alert("There was an issue verifying your credentials. Please log in again.")
                navigate("/login");
            }
        }

        if (isAuthenticated) {
            checkRole();
        }
    }, [isAuthenticated, navigate, getAccessTokenSilently]);

    // Define fetchUsers outside of useEffect
    const fetchUsers = async () => {
        const token = await getAccessTokenSilently();
        const userdata = await getUsersInfoApi(token);
        setUsers(userdata.users);
    }

    // Use fetchUsers inside useEffect for initial fetch
    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle token limit change - index is postion of user in users array
    const handleTokenLimitChange = (index: number, value: number) => {
        // create a new array with the same elements as users
        const newUsersLimit = [...users];
        // update the token limit of the user at the index
        newUsersLimit[index].token_limit = value;
        setUsers(newUsersLimit);
    }

    const handleUpdate = async (user: User) => {
        const token = await getAccessTokenSilently();
        const response = await setTokenLimitApi(user.user_id, user.token_limit, token);
        if (response.ok) {
            // show alert with success message
            alert("Successfully updated user token limit");
        } else {
            console.log("Error updating user token limit");
            alert("Error updating user token limit");
        }
    }

    // Handle reset tokens
    const handleReset = async (user: User) => {
        const token = await getAccessTokenSilently();
        const response = await resetTokensApi(user.user_id, token);
        if (response.ok) {
            alert("Successfully reset user tokens");
            // Call get users info again to update the table
            fetchUsers();
        } else {
            alert("Error resetting user tokens");
        }
    };

    if (isLoading) {
        return <div><p>Loading Users...</p></div>;
    }


    return (
        <div className='ms-Grid' dir='ltr'>
            <div className='ms-Grid-row'>
                {/* Side menu */}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogo} alt="Logo" />
                                    <Text
                                        as="h1"
                                        style={{ color: 'var(--bold-text)', fontSize: '1.5em' }}
                                    >Users</Text>
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>
                                <ManagementToolbar />
                                {/* Back to chat button */}
                                <div className={styles.backToChatBtn}>
                                    <Button
                                        appearance="secondary"
                                        onClick={() => window.location.href = "/#/hello"}
                                        icon={<Home24Filled />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >Back to Hello</Text>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




                {/* Main content - take up rest of grid 10/12*/}
                <div className='ms-Grid-col ms-lg10' style={{ height: '99vh' }}>
                    {/* Conditional check */}
                    {!isAuthorizedToView ? <div>You are not authorized to view this content</div> : (
                        <div className={styles.container}>
                            <h1>Manage Users</h1>
                            {/* Add conditional check */}
                            {!isAuthorizedToView ? <div>You are not authorized to view this content</div> : (

                                <div className={styles.tooltipContainer}>
                                    <h3>Assign token limits</h3>
                                    <Tooltip withArrow content={<div className={styles.tooltipContent}>{toolTipText}</div>} relationship="label" {...props}>
                                        <Button
                                            icon={<Info24Regular />}
                                            size="large"
                                            className={styles.tooltipButton}
                                            aria-label='Tooltip button'
                                        >Hover for Info</Button>
                                    </Tooltip>
                                    {/* Table */}
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>User ID</th>
                                                <th>Token Limit</th>
                                                <th>Update </th>

                                                <th>Tokens Used</th>
                                                <th>Reset</th>
                                                <th>Lifetime Total Tokens</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* map out users info */}
                                            {users.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{user.user_id}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={user.token_limit ?? ''}
                                                            placeholder={user.token_limit === null ? 'No Limit Set' : ''}
                                                            onChange={(e) => handleTokenLimitChange(index, Number(e.target.value))}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button onClick={() => handleUpdate(user)}>Update</button>
                                                    </td>
                                                    <td>{user.tokens_this_period}</td>
                                                    <td>
                                                        <button onClick={() => handleReset(user)}>Reset</button>
                                                    </td>
                                                    <td>{user.total_tokens}</td>


                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>)}
                </div>




                {/* Right side */}
                {/* <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                <div className={styles.rightSide}>
                    <p>Right Side</p>
                </div>
            </div> */}
            </div>
        </div>

    )
}
export default Users