import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Image, Link } from '@fluentui/react-components';
import styles from './PageHeader.module.css';
import UserProfile from '../UserProfile/UserProfile';
import ClientLogoTransparent from "../../assets/LogoTransparent.png";
import { NavLink, useLocation } from 'react-router-dom';
import { Project } from '../../api';
import { PersonaIcon } from '../PersonaIcon';
import { CollectionManagerPopup } from '../CollectionManagerPopup';
import { MyLibrary } from '../MyLibrary/MyLibrary';
import { New16Regular, New20Regular } from '@fluentui/react-icons';
import { ManagementToolbar } from '../ManagmentToolbar';

type PageHeaderProps = {
  selectedProject?: Project | undefined;
  authorizedDocumentCollectionsManager: boolean;
  authorizedBEAdmin: boolean;
  collectionRefreshTrigger?: React.Dispatch<React.SetStateAction<boolean>>;
  demoModeActive?: boolean;
}

export const PageHeader = ({
  selectedProject,
  authorizedDocumentCollectionsManager,
  authorizedBEAdmin,
  collectionRefreshTrigger,
  demoModeActive
}: PageHeaderProps) => {
  const { isAuthenticated } = useAuth0();
  const [scrolled, setScrolled] = useState(false); // State to track if the user has scrolled past a certain point
  const [refreshCollection, setRefreshCollection] = useState<boolean>(false);
  const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(authorizedDocumentCollectionsManager);

  const location = useLocation();

  //signal to refresh the collections list
  useEffect(() => {
    if (collectionRefreshTrigger) {
      collectionRefreshTrigger(refreshCollection);
    }
  }, [refreshCollection]);

  //add doc manager if authorized
  useEffect(() => {
    setIsAuthorizedToViewDocumentCollectionsManager(authorizedDocumentCollectionsManager);
  }, [authorizedDocumentCollectionsManager]);

  useEffect(() => {
    if (location.pathname === '/ai') {
      document.body.classList.add(styles.aiBody);
    } else {
      document.body.classList.remove(styles.aiBody);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Replace 100 with the desired scroll distance
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  return (

    <header className={scrolled ? styles.scrolledHeader : ''}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          {/* Client Logo */}
          <NavLink to="/hello">
            <Image className={styles.logo} src={ClientLogoTransparent} alt="Logo" />
          </NavLink>
          <div className={styles.navButtons}>
            <Link href='../#/hello'><New16Regular /> New Session</Link>
            {isAuthorizedToViewDocumentCollectionsManager ?
              <CollectionManagerPopup
                setRefreshCollection={setRefreshCollection}
                refreshCollection={refreshCollection}
              /> : ''
            }

          </div>
        </div>

        <div className={`${styles.userAndManagement} ${demoModeActive ? styles.demoClass : ''}`}>
          {authorizedBEAdmin ?
            <ManagementToolbar />
            : ''
          }
          {/* User info - wrap in isAuthenticated to prevent render leakage*/}
          <div className={styles.userDropdown}>

            {isAuthenticated ? (
              <UserProfile />
            ) : null}
          </div>
        </div>
      </div>

    </header>
  );
}