// This page will have a form where you can add line items as a description for a company.
// The items can be acronyms, definitions, projects the company has worked on, affiliations, and other information.

import { useAuth0 } from '@auth0/auth0-react';
import { TextField } from '@fluentui/react';
import { Button, Field, Image, Text, Textarea, Toaster, useToastController, Toast, ToastTitle, ToastBody, useId, ToastIntent } from '@fluentui/react-components';
import { Chat24Regular, Home24Filled } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClientLogoApi, getGoogleAnalyticsApi, getMaintenanceModeLogsApi, getTermsAndConditionsApi, setGoogleAnalyticsApi, setMaintenanceModeApi, setTermsAndConditionsApi, uploadClientLogoApi } from '../../api';
import ClientLogo from '../../assets/LogoTransparent.png';
import { ManagementToolbar } from '../../components/ManagmentToolbar';
import SystemStatus from '../../components/SystemStatus/SystemStatus';
import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './adminPage.module.css';



const CompanyProfile: React.FC = () => {
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [isMaintenanceModeActive, setIsMaintenanceModeActive] = React.useState<boolean>(false); // support access status [true/false]
    const [isDemoModeActive, setIsDemoModeActive] = React.useState<boolean>(false); // support access status [true/false]
    const [googleAnalyticsID, setGoogleAnalyticsID] = useState('');
    const [clientLogoURL, setClientLogoURL] = useState<string>('');
    const [timestamp, setTimestamp] = React.useState<string>(''); // timestamp of the most recent support access status
    const [lastUserThatCreatedRecord, setLastUserThatCreatedRecord] = React.useState<string>(''); // username of the most recent support access status
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewURL, setPreviewURL] = useState<string | null>(null);
    const [tandcText, setTandcText] = useState(''); //this is the text for the T&C
    const [tandcVersion, setTandcVersion] = useState(''); //this is the version number for T&C
    const [tandcDate, setTandcDate] = useState(''); // the last time this was updated
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // Define a notify to display the Toast messages
    const notify = (
        title: string = 'Success!', // default
        body: string = 'The operation was successful.',
        message_intent: ToastIntent = "success"
    ) =>
        dispatchToast(
            // Use the Toast component to display a message
            <Toast
                as='div'
                appearance='inverted' // dark mode
                key={undefined}
            >
                <ToastTitle>{title}</ToastTitle>
                <ToastBody><Text>{body}</Text></ToastBody>
            </Toast>,
            { intent: message_intent }
        );



    // Redirect to login page if not authenticated
    useEffect(() => {
        if (isAuthenticated) {
            // If Authenticated Replace the current entry in the history stack.
            window.history.replaceState(null, '', '/#/admin');
        } else {
            console.log("Trigger redirect to login page at [admin.tsx]");
            navigate("/login")
        }
    }, [isAuthenticated]);

    // Function to Fetch the maintenance mode status from the server
    const fetchMaintenanceMode = async () => {
        const token = await getAccessTokenSilently();
        const maintenanceModeArray = await getMaintenanceModeLogsApi(token); // fetch the data
        if (maintenanceModeArray && maintenanceModeArray.length > 0) {
            const record = maintenanceModeArray[0]; // get just the first record if multiple exist
            setIsMaintenanceModeActive(record.action === true); // any value other than 'true' from the db is considered false
            setIsDemoModeActive(record.demo === true);
            const date = new Date(record.timestamp);
            setTimestamp(date.toLocaleString());
            setLastUserThatCreatedRecord(record.username);
        }
    };

    // Call the function on page load
    React.useEffect(() => {
        fetchMaintenanceMode();
        fetchGoogleAnalyticsSettings();
        fetchTandCSettings();
        // fetchClientLogoSettings();
    }, []);

    // Function to activate maintenance mode
    const activateMaintenanceMode = async (username: string) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await setMaintenanceModeApi(username, true, false, token);
            console.log('Maintenance Mode enabled by user:', username, response);
        } catch (error) {
            console.error('Error activating maintenance mode:', error);
        }
        finally {
            fetchMaintenanceMode();
        }
    };

    // Function to deactivate maintenance mode
    const deactivateMaintenanceMode = async (username: string) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await setMaintenanceModeApi(username, false, false, token);
            console.log('Maintenance Mode disabled by user:', username, response);
        } catch (error) {
            console.error('Error deactivating maintenance mode:', error);
        }
        finally {
            fetchMaintenanceMode();
        }
    };

    // Function to activate demo mode
    const activateDemoMode = async (username: string) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await setMaintenanceModeApi(username,
                false, //activate maintenance?
                true, //activate demo?
                token,
            );
            console.log('Demo Mode enabled by user:', username, response);
        } catch (error) {
            console.error('Error activating demo mode:', error);
        }
        finally {
            fetchMaintenanceMode();
        }
    };

    // Function to deactivate demo mode
    const deactivateDemoMode = async (username: string) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await setMaintenanceModeApi(username, false, false, token);
            console.log('Demo Mode disabled by user:', username, response);
        } catch (error) {
            console.error('Error deactivating demo mode:', error);
        }
        finally {
            fetchMaintenanceMode();
        }
    };


    //google analytics settings
    const googleAnalyticsOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setGoogleAnalyticsID(newValue || '');
    }
    //set the google analytics settings
    const saveGoogleAnalyticsSettings = async () => {
        try {
            //  const token = await getAccessTokenSilently();
            const response = await setGoogleAnalyticsApi(googleAnalyticsID);
            console.log('google analytics set by user:', googleAnalyticsID, response);
        } catch (error) {
            console.error('Error saving google analytics:', error);
        }
        finally {
            fetchGoogleAnalyticsSettings();
        }
    }

    // Function to Fetch the google analytics settings from the server
    const fetchGoogleAnalyticsSettings = async () => {
        const token = await getAccessTokenSilently();
        const google_analytics_id = await getGoogleAnalyticsApi(token); // fetch the data
        if (google_analytics_id && google_analytics_id.length > 0) {
            const record = google_analytics_id[0]; // get just the last record if multiple exist
            setGoogleAnalyticsID(record.googleAnalyticsID); // any value other than 'true' from the db is considered false
        }
    };

    // Function to retreive the client logo from backend
    const fetchClientLogo = async () => {
        try {
            const token = await getAccessTokenSilently();
            const logo = await getClientLogoApi(token);
            console.log('Client Logo:', logo);
            setClientLogoURL(logo);
        } catch (error) {
            console.error('Failed to fetch client logo:', error);
        }
    };
    // Load the client logo on mount
    useEffect(() => {
        fetchClientLogo();

        // Cleanup the blob URL when the component unmounts
        return () => {
            if (clientLogoURL) {
                URL.revokeObjectURL(clientLogoURL);
            }
        };
    }, []);

    // Handle logo change
    const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            setPreviewURL(URL.createObjectURL(file));
        }
    };

    // Handle save
    const handleSaveLogo = async () => {
        if (selectedFile) {
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await uploadClientLogoApi(selectedFile, accessToken);
                console.log('Success:', response);
                // Handle success
            } catch (error) {
                console.error('Error:', error);
                // Handle error
            }
        }
    };

    //T&C version settings
    const tandcVersionOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setTandcVersion(newValue || '');
    };

    //save the T&C settings
    const saveTandCSettings = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await setTermsAndConditionsApi(tandcVersion, tandcText, accessToken);
            if (response.ok) {
                notify(
                    `Terms and Conditions`, // Title
                    'Saved successfully', // Body
                    'success' // Message intent
                );
            } else {
                throw new Error('Failed to update terms and conditions');
                notify(
                    `Terms and Conditions`, // Title
                    'FAILED TO SAVE', // Body
                    'error' // Message intent
                );
            }
        } catch (error) {
            notify(
                `Terms and Conditions`, // Title
                'FAILED TO SAVE: see console for details', // Body
                'error' // Message intent
            );
            console.error("T&C SAVE ERROR: " + error);
        } finally {
            fetchTandCSettings();
        }
    };

    // Function to Fetch the T&C settings from the server
    const fetchTandCSettings = async () => {
        try {
            const token = await getAccessTokenSilently();
            const data = await getTermsAndConditionsApi(token);
            if (data) {
                setTandcVersion(data.version);
                setTandcText(data.text);
                setTandcDate(data.timestamp || '');
            }
        } catch (error) {
            notify(
                `Terms and Conditions`, // Title
                'FAILED TO FETCH: see console for details', // Body
                'error' // Message intent
            );
            console.error("T&C FETCH ERROR: " + error);
        }
    };



    return (
        <div className='ms-Grid' dir='ltr'>
            {/* Toaster */}
            <Toaster
                toasterId={toasterId} // toasterId allows multiple to be sent
                position='top-start' // position of the toaster on the screen
            />

            <div className='ms-Grid-row'>
                {/* Side menu - 2/12ths of grid*/}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogo} alt="Logo" />
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>
                                <ManagementToolbar />
                                {/* Back to chat button */}
                                <div className={styles.backToChatBtn}>
                                    <Button
                                        appearance="secondary"
                                        onClick={() => window.location.href = "/#/hello"}
                                        icon={<Home24Filled />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >Back to Hello</Text>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Main content - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.mainContent}>
                        <h1>Admin Page </h1>
                        <SystemStatus />
                        <div className={styles.maintenanceMode}>
                            <h4>Maintenance Mode</h4>
                            <p>This mode means non-admin users cannot access the website and will log anyone out who isn't a Blue Edge Admin.</p>
                            {isMaintenanceModeActive ? (
                                <p>{lastUserThatCreatedRecord}<br></br>{timestamp}</p>
                            ) : (
                                <p>Not active</p>
                            )}
                            {/* Button wrapper */}
                            <div className={styles.buttonWrapper}>
                                {/* Enable button */}
                                <Button appearance='secondary' onClick={() => activateMaintenanceMode(user?.email || '')} disabled={isMaintenanceModeActive}>Enable Maintenance Mode</Button>
                                {/* Disable button */}
                                <Button appearance='secondary' onClick={() => deactivateMaintenanceMode(user?.email || '')} disabled={!isMaintenanceModeActive}>Disable Maintenance Mode</Button>
                            </div>
                            <h4>Demo Mode</h4>
                            <p>This mode hides all other document collections and only presents those that start with "Contoso". This is the name of the fake company that Microsoft use for their demos - we are using the same with their documents.</p>
                            <div className={styles.buttonWrapper}>
                                {/* Enable button */}
                                <Button appearance='secondary' onClick={() => activateDemoMode(user?.email || '')} disabled={isDemoModeActive}>Enable Demo Mode</Button>
                                {/* Disable button */}
                                <Button appearance='secondary' onClick={() => deactivateDemoMode(user?.email || '')} disabled={!isDemoModeActive}>Disable Demo Mode</Button>
                            </div>
                        </div>



                        <div className={styles.maintenanceMode}>
                            <h4>Logo </h4>
                            <p>Upload logo below for this server:</p>
                            <p>Current / Preview:</p>
                            {previewURL || clientLogoURL ? (
                                <Image src={previewURL || clientLogoURL} className={styles.clientLogo} alt="Client Logo" />
                            ) : (
                                'nothing here yet...'
                            )}
                            <p>Upload new logo</p>
                            <input type="file" id="file" accept="image/*" onChange={handleLogoChange} />
                            <p>Be sure to save!</p>
                            <Button style={{ width: '100px', marginTop: '5px' }} appearance='primary' onClick={handleSaveLogo}>Save logo</Button>
                        </div>




                    </div>
                </div>


                {/* Right side - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.rightSide}>
                        <div className={styles.maintenanceMode}>
                            <h4>Analytics settings</h4>
                            <p>Input the analytics tracking ID below:</p>
                            <TextField value={googleAnalyticsID} onChange={googleAnalyticsOnChange} />
                            <Button style={{ width: '200px', marginTop: '5px' }} appearance='primary' onClick={saveGoogleAnalyticsSettings}>Save Analytics Settings</Button>
                        </div>
                        {/* Terms & conditions settings */}
                        <div className={styles.maintenanceMode}>
                            <h4>Terms & Conditions Text</h4>
                            <p>Last Updated: {tandcDate}</p>
                            <Field label='T&C Version Nunber' orientation='horizontal' hint={'This MUST be a number'}>
                                <TextField value={tandcVersion} onChange={tandcVersionOnChange} />
                            </Field>
                            <p>Must be in HTML format</p>
                            <Textarea
                                resize='both'
                                value={tandcText}
                                onChange={(e) => setTandcText(e.target.value)}
                                placeholder='Use <b>example</b> for bold, <u>example</u> for underline, and <br /> for a return / new line.'
                                rows={20}
                            />
                            <Button style={{ width: '200px', marginTop: '5px' }} appearance='primary' onClick={saveTandCSettings}>Save T&C Settings</Button>

                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

export default CompanyProfile