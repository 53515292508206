import { Auth0Provider } from '@auth0/auth0-react';
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, Spinner, Theme } from "@fluentui/react-components";
import 'office-ui-fabric-core/dist/css/fabric.css'; // This is needed for ms-Grid, only need to import here no other components
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { lightTheme } from "./BlueEdgeTheme";
import { getFrontendSettingsApi } from './api';
import "./index.css";
import IndexerPage from './pages/IndexerPage/IndexerPage';
import AdminPage from './pages/adminPage/adminPage';
import Ai from "./pages/ai/Ai";
import CompanyProfile from './pages/companyProfile/companyProfile';
import Hello from "./pages/hello/Hello";
import LandingPage from "./pages/landingPage/landingPage";
import Layout from "./pages/layout/Layout";
import PersonaManager from './pages/personaManager/PersonaManager';
import Users from "./pages/users/Users";
import CollectionManager from './pages/collectionManager/CollectionManager';
import { PostHogProvider } from 'posthog-js/react'

initializeIcons(); // Fluent UI icons

// hash router
const router = createHashRouter([
    {
        path: "/",
        element: <LandingPage />,
    },
    {
        path: "/login",
        element: <LandingPage />,
    },
    {

        element: <Layout />,
        children: [
            {
                path: "/ai",
                element: <Ai />,
            },
            {
                path: "/hello",
                element: <Hello />,
                index: true
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            },
        ]
    },
    {
        path: "/personas",
        element: <PersonaManager />,
    },
    {
        path: "/users",
        element: <Users />,
    },
    {
        path: "/profile",
        element: <CompanyProfile />,
    },
    {
        path: "/indexer",
        element: <IndexerPage />,
    },
    {
        path: "/admin",
        element: <AdminPage />,
    },

    // old collections manager
    {
        path: "/collections",
        element: <CollectionManager />,
    },

    {
        path: "*",
        lazy: () => import("./pages/NoPage")
    },
]);


// App wrapper so we can use hooks to fetch settings
const App = () => {
    // Initialize state variables for Auth0 settings
    const [ clientId, setClientId ] = useState("");
    const [ domain, setDomain ] = useState("");
    const [ isLoading, setIsLoading ] = useState(true);
    const [ posthogKey, setPosthogKey ] = useState("");
    const [ posthogHost, setPosthogHost ] = useState("");

    useEffect(() => {
        // Fetch settings when component mounts
        getFrontendSettingsApi().then(settings => {
            setClientId(settings.AUTH0_CLIENT_ID);
            setDomain(settings.AUTH0_DOMAIN);
            setPosthogKey(settings.POSTHOG_KEY);
            setPosthogHost(settings.POSTHOG_HOST);
            setIsLoading(false);
        }).catch(error => {
            console.error(`Error fetching frontend settings: ${error.message}`);
            setIsLoading(false);
        });
    }, []);

    // Show spinner while fetching settings for Auth0 provider
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    // redirect_uri needs to match the allowed callback URLs in Auth0
    const redirect_uri = window.location.origin + '/#/hello/';

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirect_uri
            }}
        >
            <FluentProvider theme={lightTheme as Partial<Theme>}>
                {/* Only init posthog if both the key and host are not none */}
                {posthogKey !== "none" && posthogHost !== "none" ? (
                    <PostHogProvider
                        apiKey={posthogKey}
                        options={{ api_host: posthogHost }}
                    >
                        <RouterProvider router={router} />
                    </PostHogProvider>
                ) : (
                    // without posthog
                    <RouterProvider router={router} />
                )}
            </FluentProvider>
        </Auth0Provider>
    );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);