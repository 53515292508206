/* eslint-disable @typescript-eslint/no-unused-vars */

// This is page that the user is redirected to after logging in.
// We use it to launch into a quick tool or setup or resume a project.

import { useAuth0, User } from "@auth0/auth0-react";
import {
    Dialog,
    DialogBody, DialogContent, DialogSurface, DialogTitle,
    Image,
    Spinner
} from '@fluentui/react-components';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // to generate a new thread_id for each chat session
import { createProjectRecordApi, CustomJwtPayload, getClientLogoApi, getProjectRecordsApi, getPromptTemplatesApi, PersonaTemplate, Project, UserMetadata } from "../../api";
import { MaintenanceMode } from "../../components/MaintenanceMode";
import DemoContosoLogo from "../../assets/DemoContosoLogo.jpg";
import styles from "./Hello.module.css";
// tutorial system
import "@sjmc11/tourguidejs/src/scss/tour.scss";
import { Analytics } from "../../components/Analytics";
import { MyLibrary } from "../../components/MyLibrary/MyLibrary";
import { PageFooter } from "../../components/PageFooter/PageFooter";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import TermsOfService from "../../components/TermsOfService/TermsOfService";
import ToolSelector from "../../components/ToolSelector/ToolSelector";


interface Auth0UserWithMetadata extends Omit<User, 'user_metadata'> {
    user_metadata: UserMetadata;
}


const Hello = () => {
    const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [isAuthorizedToViewAdminMenu, setIsAuthorizedToViewAdminMenu] = useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(); // error when retreiving projects
    const [personasList, setPersonasList] = useState<PersonaTemplate[]>([])
    const [selectedPersona, setSelectedPersona] = useState<PersonaTemplate>(personasList[0]);
    const [NoRoleError, setNoRoleError] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false); // open the edit project menu
    const [projectSetup, setProjectSetup] = useState(false);
    const [projectName, setProjectName] = useState('') // name of the project
    const [newProjectId, setNewProjectId] = useState('') // name for the backend
    const [projectDescription, setProjectDescription] = useState('') // project description
    const [projectGizmo, setProjectGizmo] = useState<PersonaTemplate>() // selected gizmo for project
    const [projects, setProjects] = useState<Project[]>([]);
    const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [refreshCollection, setRefreshCollection] = useState<boolean>(false);
    const [clientLogoURL, setClientLogoURL] = useState<string>('');
    const [userMetadata, setUserMetadata] = useState<UserMetadata[]>([]);
    const [isDemoModeActive, setIsDemoModeActive] = React.useState<boolean>(false); // demo mode status [true/false]

    // redirect to login if not authenticated
    useEffect(() => {
        if (isAuthenticated) {
            // If Authenticated Replace the current entry in the history stack.
            window.history.replaceState(null, '', '/#/hello');
        } else {
            console.log("Trigger redirect to login page at [hello.tsx]");
            // navigate("/login")
        }
    }, [isAuthenticated]);

    // Fetch project records
    const fetchProjectRecords = async (): Promise<Project[]> => {
        const access_token = await getAccessTokenSilently();
        try {
            // Call the function to get all project records
            const allProjects = await getProjectRecordsApi(access_token);

            // Check if allProjects is null or undefined
            if (!allProjects || !Array.isArray(allProjects)) {
                console.error('No project records found.');
                return [];
            }

            // Transform the fetched data to match the Project model
            const collectedProjects: Project[] = allProjects
                .filter(myProjects => myProjects.owner === user?.email)
                .map((project) => ({
                    id: project.id,
                    project_name: project.project_name,
                    project_description: project.project_description,
                    owner: project.owner,
                    project_tool_name: project.project_tool_name,
                    project_tool_id: project.project_tool_name,
                    project_collections: project.project_collections,
                    last_updated: project.last_updated,
                    prompts: project.prompts,
                    results: project.results,
                    variableInputs: project.variableInputs
                }));

            // Log or return the transformed project records
            return collectedProjects;
        } catch (error) {
            // Handle any errors that occur during the fetch
            console.error('Error fetching project records:', error);
            throw error;
            return [];
        }
    };

    // useEffect to fetch project records when loadingProjects changes
    useEffect(() => {
        // Define an async function to fetch and set the data
        const loadProjects = async () => {
            try {
                const fetchedProjects = await fetchProjectRecords();
                setProjects(fetchedProjects); // Set the fetched projects
            } catch (error) {
                setError('Failed to fetch projects');
            } finally {
                setLoadingProjects(false);
            }
        };

        loadProjects(); // Call the async function
    }, [loadingProjects]);


    // Update the project name and description when a project is selected or changed
    useEffect(() => {
        if (selectedProject) {
            setProjectName(selectedProject.project_name);
            setProjectDescription(selectedProject.project_description);
        }
    }, [selectedProject]); // This will run whenever selectedProject changes

    // handle edit project button
    const handleEditProject = async (project: Project) => {
        setSelectedProject(project);
        setOpen(true)
    }

    // handle project cancel button
    const handleCancelProject = () => {
        setSelectedProject(undefined);
        setProjectDescription('');
        setProjectName('');
        setProjectGizmo(undefined);
        setProjectSetup(false);
        setOpen(false);
    }

    // handle a new project creation
    const handleNewProject = (toolId: PersonaTemplate) => {
        // console.log("Creating new project with tool: ", toolId);
        createNewProject(toolId); // added a check to response to make it wait before navigating
    }


    // Get permissions
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                // Document Collections Manager
                if (permissions.includes('view:Dashboard')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                // Admin Settings Menu
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewAdminMenu(true);
                }
                //if no role assigned
                if (!permissions.includes('read:Files')) {
                    setNoRoleError(true);
                }
            } catch (e) {
                // Handle errors - e.g. token is invalid, expired, etc.
                console.error(e);
                alert("There was an issue verifying your credentials. Please log in again.")
                navigate("/login");
            }
        }
        checkRole();
    }, [])


    useEffect(() => {
        if (user?.user_metadata) {
            // Type assertion since we know the shape of our metadata
            const metadata = user.user_metadata as unknown as UserMetadata;

            setUserMetadata([
                {
                    full_name: metadata.full_name || 'no name',
                    job_title: metadata.job_title || 'no job',
                    company_name: metadata.company_name || 'no company',
                    company_address: metadata.company_address || 'no address'
                }
            ]);
        }
    }, [user]);


    // Get Personas
    const loadPersonas = async () => {
        try {
            const token = await getAccessTokenSilently();
            if (token) {
                const personas = await getPromptTemplatesApi(token);
                setPersonasList(personas);
            }
        } catch (error) {
            console.error('Failed to retrieve access token silently', error);
            setPersonasList([]) // blank to avoid render error
        }
    };

    // load personas on mount & remove project id
    useEffect(() => {
        localStorage.removeItem('project');
        loadPersonas();
    }, []);


    //Create new project
    const createNewProject = async (persona: PersonaTemplate) => {
        // console.log("MAKING NEW PROJECT");
        const projectId = uuidv4();

        try {
            const token = await getAccessTokenSilently();
            const response: string = await createProjectRecordApi(
                projectId, //id
                'New Session', //project_name
                '', // project_description
                user?.email || 'emailAddress', //owner
                persona?.name || 'none', //project_tool_name
                persona?.id || 'none', //project_tool_id
                'new', // project_collections
                Date(), // last_updated
                'new', // prompts
                [], // results
                '', //variable inputs
                token,
            );
            // console.log("Response from createProjectRecordApi: ", response);
            // wait for response and check if it was successful
            if (response !== '') {
                // console.log("ID created: ", projectId);
                setNewProjectId(projectId)
                localStorage.setItem('project', projectId);
                // console.log("New project created with id: ", projectId);
            }
            navigate("../ai")

        } catch (error) {
            console.error('Error creating project:', error);
        }
    }

    // Function to retreive the client logo from backend
    const fetchClientLogo = async () => {
        try {
            const token = await getAccessTokenSilently();
            const clientLogo = await getClientLogoApi(token);
            setClientLogoURL(clientLogo);
        } catch (error) {
            console.error('Failed to fetch client logo:', error);
        }
    };

    // Load the client logo on mount
    useEffect(() => {
        fetchClientLogo();

        // Cleanup the blob URL when the component unmounts
        return () => {
            if (clientLogoURL) {
                URL.revokeObjectURL(clientLogoURL);
            }
        };
    }, []);

    // Auth
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    return (
        <>
            <div className={styles.container}>
                <PageHeader authorizedDocumentCollectionsManager={isAuthorizedToViewDocumentCollectionsManager} authorizedBEAdmin={isAuthorizedToViewAdminMenu} demoModeActive={isDemoModeActive} />

                {/* invisible components */}
                <Analytics />
                <MaintenanceMode setDemoMode={setIsDemoModeActive} />

                {/*error: no role message*/}
                <Dialog open={NoRoleError} modalType="alert">
                    <DialogSurface mountNode={undefined}>
                        <DialogBody>
                            <DialogTitle>Account not yet verified</DialogTitle>
                            <DialogContent>
                                You account provisioning has now started and is normally completed within 20 minutes but can take up to 24 hours.
                                You will receive an email notification once your account has been fully setup and you can start using the app. Contact <a href="emailto:support@blue-edge.ai">support@blue-edge.ai</a> if you are still receiving this message 24 hours after sign up.
                            </DialogContent>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>

                {/* Main section */}
                <div className={styles.helloSectionFull}>
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg4">
                                <div className={styles.mainContainer}></div>


                                <h1 className={styles.helloHeading} >Hello.</h1>

                                <div className={styles.clientLogoContainer}>
                                    <Image src={isDemoModeActive ? DemoContosoLogo : clientLogoURL} className={styles.clientLogo} />
                                </div>

                                {isDemoModeActive ?
                                    <p className={styles.gizmoComponentDescription}>Welcome to Blue Edge AI.</p>
                                    :
                                    <p className={styles.gizmoComponentDescription}>Welcome {userMetadata.map((meta, index) => (
                                        <span key={index}>{meta.full_name}.</span>
                                    ))}</p>
                                }

                                <p className={styles.gizmoComponentDescription}>Ready to harness the best of your bids, tenders, proposals, faster and easier.</p>
                            </div>

                            {/* My Projects section */}
                            <div className="ms-Grid-col ms-lg8">
                                <TermsOfService />

                                {/* New tool selector first retrieves all categories then sorts the tools*/}
                                <h3 className={styles.gizmoComponentSubheading}>What would you like to do today?</h3>
                                <ToolSelector personasList={personasList} handleToolSelect={toolId => handleNewProject(toolId)} />
                                <h3 className={styles.gizmoComponentSubheading}>Recommended for you</h3>

                                <MyLibrary
                                    projectList={projects}
                                    personasList={personasList}
                                    loadingProjects={loadingProjects}
                                    setLoadingProjects={setLoadingProjects}
                                    handleRecentToolSelect={tool => handleNewProject(tool)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <PageFooter />
        </>
    );
};

export default Hello;
