// This page will have a form where you can add line items as a description for a company.
// The items can be acronyms, definitions, projects the company has worked on, affiliations, and other information.

import { useAuth0 } from '@auth0/auth0-react';
import type { InputProps } from "@fluentui/react-components";
import { Button, Image, Label, Spinner, Text, Textarea, Toast, Toaster, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import { ArrowClockwiseDashesRegular, Home24Filled } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCompanyProfileApi, updateCompanyProfileApi } from '../../api';
import ClientLogo from '../../assets/LogoTransparent.png';
import { MaintenanceMode } from "../../components/MaintenanceMode";
import { ManagementToolbar } from '../../components/ManagmentToolbar';
import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './companyProfile.module.css';


// Define the type for the company profile and the fields that will be in the form
type CompanyProfileType = {
    company_profile_text: string
}

// Function to get total character count of all form fields
// const getTotalCharacterCount = (formData: CompanyProfileType) => {
//     let totalCharacterCount = 0;
//     for (const key in formData) {
//         if (formData[key]) {
//             totalCharacterCount += formData[key].length;
//         }
//     }
//     return totalCharacterCount;
// };


const CompanyProfile: React.FC = (props: InputProps) => {
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const navigate = useNavigate();
    const [ isUpdating, setIsUpdating ] = React.useState(false); // Track loading
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);



    // state to track form data - Initialize with empty strings - this will make the components controlled by default
    const [ formData, setFormData ] = React.useState<CompanyProfileType>({
        company_profile_text: ''
    });


    // Check auth
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login"); // Navigate the user to login if they are not authenticated.
        }
    }, [ isAuthenticated, navigate ]);

    // Function to get company profile
    const getCompanyProfile = async () => {
        // Get the access token
        const token = await getAccessTokenSilently();
        // Call the getCompanyProfileApi function
        try {
            const profiles = await getCompanyProfileApi(token); // returns array
            if (profiles.length > 0) {
                const companyProfile = profiles[ 0 ] // get just the first company profile(there will be only 1)
                setFormData(companyProfile); // set the form data to the company profile
            } else {
                setFormData({
                    company_profile_text: ''
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            let errorMessage = 'Updating company profile failed:';
            // If the error response has a message property, use it
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            console.error(`Getting company profile failed: ${errorMessage}`);
        }
    };

    // useEffect to getCompanyProfile on page load
    useEffect(() => {
        getCompanyProfile();
    }, []);

    // Submit form
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsUpdating(true); // Set loading to true

        // Get the access token
        const token = await getAccessTokenSilently();

        // Convert formData to FormData
        const formDataObj = new FormData();
        formDataObj.append('company_profile_text', formData.company_profile_text);


        // Call the updateCompanyProfileApi function
        try {
            const data = await updateCompanyProfileApi(formDataObj, token);
            // extract the message
            const successMessage = data.success
            // Dispatch success toast
            dispatchToast(
                <Toast>
                    <ToastTitle>{successMessage}</ToastTitle>
                </Toast>,
                { intent: 'success' }
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            let errorMessage = 'Updating company profile failed';

            // If the error response has a message property, use it
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            // Dispatch error toast
            dispatchToast(
                <Toast>
                    <ToastTitle>{errorMessage}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        } finally {
            setIsUpdating(false); // Set loading to false
        }
    };

    // Function to handle input changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [ name ]: value }));
    };

    // const totalCharacterCount = getTotalCharacterCount(formData); // Get total character count of all form fields

    // While auth is loading show indicator
    if (isLoading) {
        return <div><Spinner /></div>;
    }


    return (
        <div className='ms-Grid' dir='ltr'>
            <MaintenanceMode />
            <div className='ms-Grid-row'>
                {/* Side menu - 2/12ths of grid*/}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogo} alt="Logo" />
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>
                                <ManagementToolbar />
                                {/* Back to chat button */}
                                <div className={styles.backToChatBtn}>
                                    <Button
                                        appearance="secondary"
                                        onClick={() => window.location.href = "/#/hello"}
                                        icon={<Home24Filled />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >Back to Hello</Text>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Main content - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.mainContent}>
                        <h1>Company Profile </h1>

                        <p>Information entered here will be passed to the AI with each chat interaction. Let's try to keep the total characters under 1000 total for all fields.</p>

                        <div className={styles.refreshContainer}>
                            {/* Button to refresh */}
                            <Button
                                onClick={getCompanyProfile}
                                icon={<ArrowClockwiseDashesRegular />}
                                appearance='transparent'
                                title='Refresh'
                            />
                        </div>


                        {/* Toaster - can be anywhere in the return of component- pass position for location*/}
                        <Toaster
                            toasterId={toasterId}
                            mountNode={undefined}
                            position={"top-end"} // top right
                        />
                        <br />


                        {/* Form */}
                        <form
                            className={styles.form}
                            onSubmit={handleSubmit}
                        >
                            {/* Company Name */}
                            <Label
                                htmlFor='company_profile_text'
                                size={props.size}
                            >
                                Company Profile
                            </Label>
                            <Textarea
                                name='company_profile_text'
                                value={formData.company_profile_text}
                                onChange={handleInputChange}
                                placeholder='Company Profile'
                                aria-label='Company Profile'
                                rows={5} // This sets the height to approximately 5 lines
                                style={{ width: '100%' }} // Optional: Ensures the textarea takes up the full width
                            />

                        </form>
                    </div>
                </div>








                {/* Right side - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.rightSide}>
                        <Text
                            as='h2'
                            align='center'
                            size={500}
                        >Save your changes</Text>
                        {/* Submit */}
                        <Button
                            type='submit'
                            onClick={handleSubmit}
                            disabled={isUpdating}
                            appearance='outline'
                        >
                            {isUpdating ? (
                                <>
                                    <Spinner
                                        size={"tiny"}
                                        label="..saving"
                                    />
                                </>
                            ) : 'Submit'}
                        </Button>
                    </div>
                </div>
            </div>

        </div >
    );
}

export default CompanyProfile